@import "../../../../assets/scss/mixin";
.card {
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  padding: 30px;
  margin-bottom: 16px;
  flex-direction: column;
  @media (max-width: $breakpoint_laptop) {
    padding: 25px;
  }
  .card-body { padding: 0}
  .card-title {
    margin-bottom: 36px;
    @media (max-width: $breakpoint_laptop) {
      margin-bottom: 25px;
    }
    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      @media (max-width: $breakpoint_laptop) {
        font-size: 22px;
      }
    }
  }
}
.form-group {
  label {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    line-height: 24px;
    align-items: center;
    margin: 0 0 16px;
    color: #000;
    small {
      display: block;
    }
    @media (max-width: $breakpoint_laptop) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}
.edit-form-wrapper {
  @media (max-width: $breakpoint_laptop) {
    .passwordHide:before {
      right: 37px;
    }
  }
  & > ul {
    margin: 0 -15px;
    & > li {
      padding: 0 15px;
      width: 50%;
      margin-bottom: 35px;
      @media (max-width: $breakpoint_laptop) {
        margin-bottom: 20px;
      }
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 100%;
        .add-entity-btn { margin-top: 0}
      }
      &:last-child { margin-bottom: 10px}
      & > ul {
        &.time-wrapper {
          @media (max-width: $breakpoint_mobile_portrait) {
            flex-direction: column;
          }
          li {
            margin: 0 !important;
            @media (max-width: $breakpoint_mobile_portrait) {
              width: 100%;
              margin-bottom: 20px !important;
              &:last-child { margin-bottom: 0 !important;}
            }
          }
        }
        margin: 0 -15px;
        position: relative;
        li {
          padding: 0 15px;
          width: 50%;
          @media (max-width: $breakpoint_mobile_portrait) {
            width: 100%;
          }
          // &:nth-child(2) {
          //   .del-field-btn { pointer-events: none}
          // }
          margin-bottom: 35px;
          @media (max-width: $breakpoint_laptop) {
            margin-bottom: 20px;
          }
          &:last-child { margin-bottom: 10px}
          .form-group{
            .passwordHide {
              &:before {
                right: 27px;
              }
            }
          }
        }
      }
    }
  }
}
.password{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.panel-content {
  .password .form-control{
    margin-right: -20px;
  }
}
.password img{
  position: relative;
  right: 30px;
  max-height: 15px;
  cursor: pointer;
}
.multi-field {
  position: relative;
  width: calc(100% - 37px);
  .del-field-btn {
    position: absolute;
    right: -36px;
    bottom: 22px;
    padding: 0;
    background: none;
    border: none;
    @media (max-width: $breakpoint_laptop) {
      bottom: 16px;
    }
  }
}

.validator li{
  display: flex;
  align-items: center;
}
.add-address-btn {
  padding: 0;
  margin-left: 10px;
  background: none;
  border: none;
  z-index: 1;
  img {
    height: 19px;
    @media (max-width: $breakpoint_laptop) {
      height: 16px;
    }
  }
  @media (max-width: $breakpoint_laptop) {
    height: 16px;
  }
}
.form-group {
  .check-box {
    margin-right: 20px;
    display: inline-block;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    &:last-child { margin-right: 0}
    @media (max-width: $breakpoint_laptop) {
      font-size: 17px;
      line-height: 22px;
      margin-right: 10px;
    }
    input { position: absolute;
      opacity: 0;}
    span {
      display: flex;
      cursor: pointer;
      align-items: flex-start;
      position: relative;
      padding-left: 40px;
      @media (max-width: $breakpoint_laptop) {
        padding-left: 30px;
      }
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        border: 1px solid #999;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 3px;
        @media (max-width: $breakpoint_laptop) {
          top: 1px;
        }
      }
    }
    input[type='checkbox']:checked ~ span:before {
      background: var(--theme-color);
      border-color: var(--theme-color);
      content: '\f00c';
      font-family: FontAwesome;
      font-size: 15px;
      line-height: 1.2;
      padding-left: 0;
      color: #fff;
    }
  }
  .check-radio {
    &:last-child { margin-right: 0}
    margin-right: 20px;
    display: inline-block;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    position: relative;
    @media (max-width: $breakpoint_laptop) {
      font-size: 17px;
      line-height: 22px;
    }
    input { position: absolute;
      opacity: 0;}
    span {
      display: flex;
      cursor: pointer;
      align-items: flex-start;
      position: relative;
      padding-left: 40px;
      @media (max-width: $breakpoint_laptop) {
        padding-left: 30px;
      }
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        border: 1px solid #999;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 1px;
        @media (max-width: $breakpoint_laptop) {
          top: 1px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 1px;
        top: 2px;
        width: 18px;
        display: none;
        height: 18px;
        border: 1px solid #FFFFFF;
        border-radius: 100%;
        margin: 0;
      }
    }
    input[type='radio']:checked ~ span:before {
      background: var(--theme-color);
      border-color: var(--theme-color);
      color: #fff;
    }
    input[type='radio']:checked ~ span:after {
      display: flex;
    }
  }
}
.form-group {
  .switch-wrap {
    margin: 0;
    display: inline-block;
    span {
      cursor: pointer;
      display: flex;
      width: 55px;
      background: #D4D4D4;
      height: 26px;
      border-radius: 40px;
      position: relative;
      @include transition;
      &:before {
        content: '';
        position: absolute;
        left: 4px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        top: 5px;
        @include transition;
        background: #F1F1F1;
      }
    }
    input { position: absolute; opacity: 0;}
    input[type="checkbox"]:checked ~ span {
      background: #9C8831;
      &:before {
        //background: #22EB4E;
        left: 100%;
        transform: translateX(-20px);
      }
    }
  }
}
.add-entity-btn{ margin-top: 35px;}
.radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-wrap: wrap;
  }
  .form-group {
    width: auto;
    margin-right: 20px;
    @media (max-width: $breakpoint_mobile_portrait) {
      width: 100%;
    }
  }
}
.disable_input {
  input{
    background-color: #F7F7F7 !important;
  }
}
.increment-wrap {
  input {
    border: 1px solid #999999;
    border-left: none;
    border-right: none;
    height: 39px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    width: 120px;
  }
  button {
    background: transparent;
    border: 1px solid #999999;
    height: 39px;
    width: 40px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child { border-radius: 5px 0 0 5px; padding-bottom: 6px;}
    &:last-child { border-radius: 0 5px 5px 0}
  }
}
.profile-wrapper {
  text-align: center;
  margin-bottom: 30px;
  
  img {
    border-radius: 50%;
    height: 110px;
    width: 110px;
  }
}