@import "../../assets/scss/mixin";
.modal-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0,0,0,0.5);
  z-index: 99999;
  &.reminder-modal {
    .modal-dialog {
      max-width: 480px;
      h3 {
        margin: 0 0 30px;
        font-size: 28px;
        text-align: center;
      }
      p {
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 36px;
        span {
          color: var(--theme-second-color);
        }
        time {
          display: block;
        }
      }
    }
  }
  &.inner_modal {
    .modal-dialog { max-width: 600px !important;}
  }
  &.lead-details-modal {
    .modal-dialog {
      max-width: 1080px;
      @media (max-width: $breakpoint_laptop) {
        max-width: 1000px;
      }
    }
  }
  &.detail-wrapper {
    .modal-dialog {
      max-width: 1560px;
      @media (max-width: $breakpoint_laptop) {
        max-width: 1200px;
      }
    }
  }
  .m3{
    margin-left: 20px;
  }
  .modal-dialog{
    min-height: calc(100% - (1.75rem * 2));
    max-width: 835px;
    padding: 0 10px;
    margin: 1.75rem auto;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    @media (max-width: $breakpoint_laptop) {
      max-width: 650px;
    }
    .modal-content{
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 0.3rem;
      outline: 0;
      .modal-body{
        flex: 1 1 auto;
        padding: 25px 20px 30px;
        position: relative;
        .cross-btn {
          position: absolute;
          right: 5px;
          top: 5px;
        }
        &.alert-modals {
          padding: 80px 30px 60px;
          @media (max-width: $breakpoint_laptop) {
            padding: 50px 30px;
          }
        }
      }
    }
  }
}
.modal-forms {
  h2 {
    margin: 0 0 60px;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    @media (max-width: $breakpoint_laptop) {
      margin: 0 0 40px;
      font-size: 22px;
    }
  }
  p {
    text-align: center;
    margin-bottom: 30px;
  }
  .modal-btn { margin: 0 15px;}
  & > ul{
    & > li {
      margin-bottom: 20px;
      .form-group ~  .form-group {
        margin-top: 20px;
      }
      .modal-btn { margin: 0 5px;}
      &:last-child{margin-bottom: 0;}
      .form-input { box-shadow: 2px 2px 9px rgb(0 0 0 / 12%)}
    }
  }
}
.modal-btn {
  display: flex;
  z-index: 1;
  background: var(--theme-color);
  text-transform: capitalize;
  color: var(--white-color);
  padding: 13px 30px;
  width: 100%;
  max-width: 200px;
  white-space: nowrap;
  border: 2px solid var(--theme-color);
  font-size: 24px;
  font-weight: 500;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  position: $position_rel;
  img {
    height: 18px;
    margin-right: 10px;
  }
  &.cancel {
    background: none;
    color: #999999;
    border-color: #999999;
    &:hover {
      background: none;
      color: var(--black-color);
      border-color: var(--black-color);
    }
  }

  @include transition;
  @media (max-width: $breakpoint_laptop) {
    font-size: 15px;
    padding: 11px 24px;
    max-width: 160px;
  }

  &.pricing-btn {
    width: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background: var(--theme-second-color);
    border-color: var(--theme-second-color);
  }
}
//user-detail-modal
.user-detail-modal {
  .modal-btn {
    margin: 0 5px;
    max-width: 156px;
    @media (max-width: $breakpoint_laptop) {
      max-width: 120px;
    }
  }
  h2 {
    margin: 0 0 10px;
    font-weight: 600;
    font-size: 28px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 22px;
    }
  }
  .user-detail-wrapper {
    margin: 0 -10px;
    & > div {
      width: 50%;
      padding: 0 10px;
    }
    section {
      box-shadow: 0 30px 72px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      padding: 30px;
      margin-bottom: 20px;
      background: #FFFFFF;
      h3 {
        margin: 0 0 30px;
        font-weight: 600;
        font-size: 24px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      ul {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 35px;
          @media (max-width: $breakpoint_laptop) {
            margin-bottom: 15px;
          }
          &:last-child { margin-bottom: 0}
          h4 {
            margin: 0;
            font-size: 24px;
            font-weight: 500;
            @media (max-width: $breakpoint_laptop) {
              font-size: 15px;
            }
          }
          span {
            margin-left: auto;
            font-size: 24px;
            text-align: right;
            @media (max-width: $breakpoint_laptop) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
.cross-btn { cursor: pointer}
.docs-list {
  display: flex;
  flex-wrap: wrap;
  span {
    width: 50%;
    display: inline-block;
    position: relative;
    font-size: 20px;
    padding: 0 0 0 30px;
    margin-bottom: 20px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 14px;
      padding-left: 20px;
      margin-bottom: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      border-radius: 100%;
      left: 0;
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      @media (max-width: $breakpoint_laptop) {
        width: 10px;
        height: 10px;
        top: 7px;
      }
    }
  }
}
.file-upload-modal {
  text-align: center;
  position: relative;
  border: 1px dashed #9C8831;
  border-radius: 12px;
  padding: 10px;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    opacity: 0;
    cursor: pointer;
  }
  section {
    min-height: 157px;
    background: rgba(156, 136, 49, 0.1);
    img { margin-bottom: 19px;}
    span {
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }
}
.doc-image {
  & > img {
    width: 100%;
    max-width: 600px;
  }
}

.pdpa-deletion{
  h4{
    // font-size: 12px;
    padding: 0px;
    margin: 0px;
  }
  button{
    width: 100%;
    max-width: none !important;
  }
  .pdpa-divs{
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
    .pad-left{
      padding: 20px;
      width: 48%;
      background-color: #F6F6F6;
      max-height: 300px;
      overflow-y: auto;
      
      label{
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      input{
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }
      h4{
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}
.no-p-styling {
  p {
    text-align: left;
  }
}
.ql-container.ql-disabled .ql-tooltip{
  display: none;
}
.set-image-size{
  img{
    max-width: 1000px;
  }
}
.index-picker .react-date-picker__calendar{
  z-index: 2;
}