@import "../../../../assets/scss/mixin";
.statistics-wrapper {
  @media (max-width: $breakpoint_mobile_portrait) {
    &.grid_kbad {
      ul {
        margin: 0;
        li {
          figure {
            flex: 0 0 75px;
            height: 75px;
            width: 75px;
            padding: 17px;
          }
          a {
            flex-direction: row;
          justify-content: space-between;
          padding: 15px 40px 15px 30px;
          section {
            text-align: left;
            margin-right: 0;
            h2 {
              font-size: 28px;
            }
            span {
              white-space: nowrap;
              font-size: 16px;
            }
          }
          &:before {
            display: none;
          }
          }
        }
      }
    }    
  }
  a{
    cursor: auto !important;
  }
  ul {
    margin: 0 -25px;
    @media (max-width: $breakpoint_laptop) {
      margin: 0 -15px;
    }
    @media (max-width: $breakpoint_tablet) {
      flex-wrap: wrap;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      flex-direction: row;
    }
    li {
      width: 33.333%;
      padding: 0 25px;
      position: relative;
      @media (max-width: $breakpoint_laptop) {
        padding: 0 15px;
      }
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 50%;
        margin-bottom: 15px;
      }
      a {
        background: #FFFFFF;
        box-shadow: 0 30px 72px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        padding: 0 76px 0 36px;
        position: relative;
        min-height: 216px;
        @include transition;
        &:hover { box-shadow: 0 10px 30px rgba(0,0,0,0.2)}
        @media (max-width: $breakpoint_laptop) {
          padding: 0 40px 0 20px;
          min-height: 120px;
        }
        @media (max-width: $breakpoint_miniX_laptop) {
          min-height: 105px;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0 40px 15px 15px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          flex-direction: column-reverse;
          align-items: center;
          padding: 15px 40px 15px 15px;
          height: 100%;
        }
        &:before {
          content: '';
          position: absolute;
          right: 36px;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../../../assets/images/right-angle-arrow.svg") no-repeat;
          background-size: contain;
          width: 15px;
          height: 35px;
          @media (max-width: $breakpoint_laptop) {
            width: 10px;
            height: 20px;
            right: 18px;
            top: 20%;
            transform: none;
          }
        }
        &.no-arrow{
          &::before{
            content: '';
            opacity: 0;
          }
        }
        section {
          @media (max-width: $breakpoint_mobile_portrait) {
            margin-right: -25px;
            text-align: center;
          }
          h2 {
            font-size: 48px;
            margin: 0 0 25px;
            @media (max-width: $breakpoint_laptop) {
              font-size: 28px;
              margin-bottom: 6px;
            }
            @media (max-width: $breakpoint_miniX_laptop) {
              font-size: 21px;
            }
          }
          h3 {
            font-size: 23px;
            margin: 0 0 10px;
            line-height: 1.2;
            padding-right: 10px;
            @media (max-width: $breakpoint_laptop) {
              font-size: 20px;
              margin-bottom: 6px;
            }
            @media (max-width: $breakpoint_miniX_laptop) {
              font-size: 16px;
            }
          }
          span {
            font-size: 24px;
            white-space: pre-line;
            line-height: 29px;
            display: block;
            @media (max-width: $breakpoint_laptop) {
              font-size: 14px;
              line-height: 20px;
            }
            @media (max-width: $breakpoint_miniX_laptop) {
              font-size: 13px;
            }
            @media (max-width: $breakpoint_mini_laptop) {
              font-size: 12px;
            }
            small {
              font-size: 16px;
              color: #56C662;
              margin-left: 3px;
              &.low {
                color: #C65656;
              }
              img {
                vertical-align: middle;
                margin-bottom: 4px;
                @media (max-width: $breakpoint_laptop) {
                  height: 10px;
                }
              }
              @media (max-width: $breakpoint_laptop) {
                font-size: 12px;
              }
            }
          }
        }
        figure {
          flex: 0 0 124px;
          height: 124px;
          width: 124px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 0 auto;
          background: #FFFFFF;
          box-shadow: 0 24px 60px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          @media (max-width: $breakpoint_laptop) {
            flex: 0 0 70px;
            height: 70px;
            width: 70px;
            padding: 13px;
          }
          @media (max-width: $breakpoint_miniX_laptop) {
            flex: 0 0 50px;
            height: 50px;
            width: 50px;
            padding: 12px;
          }
          @media (min-width: 601px) and (max-width: $breakpoint_tablet) {
            flex: 0 0 40px;
            height: 40px;
            width: 40px;
            position: absolute;
            bottom: -10px;
            right: 10px;
            padding: 8px;
          }
          @media (max-width: $breakpoint_mobile_portrait) {
            margin: 0 0 10px 10px;
          }
          img { max-width: 100%;}
        }
      }
    }
  }
}
.statistics-wrap {
  display: flex;
  margin: 0 -25px 40px;
  @media (max-width: $breakpoint_laptop) {
    margin: 0 -15px 40px;
  }
  @media (max-width: $breakpoint_miniX_laptop) {
    margin-bottom: 30px;
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column;
  }
  .statistics-wrapper {
    // width: 66.6667%;
    padding: 0 25px;
    @media (max-width: $breakpoint_laptop) {
      padding: 0 15px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      width: 100%;
    }
    ul {
      @media (max-width: $breakpoint_mobile_portrait) {
        justify-content: center;
        flex-wrap: wrap;
      }
      li {
        margin-bottom: 20px;
        width: 50%;
        &:nth-child(3),&:last-child { margin-bottom: 0}
        @media (max-width: $breakpoint_miniX_laptop) {
          margin-bottom: 15px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          margin-bottom: 15px !important;
        }
      }
    }
    &.side-wrapper {
      width: 33.333%;
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 100%;
      }
      ul {
        height: 100%;
        li {
          height: 100%;
          width: 100%;
          margin-bottom: 0;
          a {
            height: 100%;
            flex-direction: column-reverse;
            justify-content: center;
            &:before {
              transform: translateY(200%);
              top: 50%;
              @media (max-width: $breakpoint_tablet) {
                transform: translateY(-50%);
              }
            }
            @media (max-width: $breakpoint_mobile_portrait) {
              height: 150px;
              flex-direction: row;
              padding-bottom: 30px;
            }
            section {
              margin-right: auto;
            }
            figure {
              margin: 0 0 78px;
              @media (max-width: $breakpoint_laptop) {
                margin-bottom: 20px;
              }
              @media (max-width: $breakpoint_tablet) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
.statistics-tabs {
  position: absolute;
  left: 32px;
  font-weight: 500;
  bottom: 0;
  span {
    margin-right: 17px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-bottom: 18px;
    color: #999999;
    @media (max-width: $breakpoint_laptop) {
      padding-bottom: 8px;
    }
    @media (max-width: $breakpoint_mobile_landscape) {
      padding-bottom: 5px;
      font-size: 12px;
      margin-right: 7px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: var(--theme-color);
      @include transition;
    }
    &:hover,&.active {
      color: var(--theme-color);
      &:before {
        left: 0;
        width: 100%;
      }
    }
  }
}