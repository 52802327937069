@import "../../../../assets/scss/mixin";
.entity-tabs {
  ul {
    li {
      margin-right: 6px;
      button {
        border: none;
        background: #E9E9E9;
        color: #000000;
        font-size: 22px;
        padding: 0 37px;
        height: 52px;
        border-radius: 4px;
        &.active {
          background: var(--theme-color);
          color: #FFFFFF;
        }
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
          padding: 0 31px;
          height: 45px;
        }
      }
    }
  }
}