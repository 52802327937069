@import "./mixin";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
:root {
  --color-page-background: #{$dark_color};
  --color-text: #{$white_color};
  --white-color: #{$white_color};
  --black-color: #{$dark_color};
  --theme-color: #{$theme_color};
  --theme-second-color: #{$theme_second_color};
  --font-family: 'Montserrat', sans-serif;
}
.pb-50{
  padding-bottom: 50px;
}
body {
  margin: 0;
  background: #fff;
  color: var(--black-color);
  -webkit-text-size-adjust:  $w_100;
  -ms-text-size-adjust: none;
  font: 16px/25px var(--font-family);
  overflow-x: $hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
  }
}
h1,h2,h3,h4,h5,h6 {
  line-height: 1.5;
  font-family: var(--font-family);
}
.w-100 {
  width: $w_100 !important;
  max-width: $w_100 !important;
}
.w-50{
  width: 50% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}
.max-width {
  @include max_width;
}
.list-style-none {
  @include list_style_none;
}
.text-break{
  overflow-wrap: break-word;
}
.d-block {
  display: $d_block;
}
.d-none {
  display: $d_none;
}
.flex {
  display: $flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.content-justify-center {
  justify-content: center;
}
.content-justify-between {
  justify-content: space-between;
}
.content-justify-start {
  justify-content: flex-start;
}
.content-justify-end {
  justify-content: flex-end;
}
.column-direction {
  flex-direction: column;
}
.row-direction {
  flex-direction: row;
}
.font-gray{
  color: gray;
}
.mx-20{
  margin-left: 20px;
  margin-right: 20px;
}
.quarter-table{
  display: flex;
  width: 1300px;
  border: 1px solid gray;
  font-weight: bold;

  span:nth-child(1){
    width:800px;
    text-align: center;
    border-right: 1px solid gray;
  }
  span:nth-child(2){
    width:500px;
    text-align: center;
  }
}
.quarter-selector{
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
  .active{
    background-color: #a28731;
    font-weight: bold;
    color: white;
  }
  span:nth-child(1){
      border: 1px solid gray;
      border-right: 0px;
      padding: 5px;
  }
  span:nth-child(2){
    border: 1px solid gray;
    border-right: 0px;
    padding: 5px;
  }
  span:nth-child(3){
    border: 1px solid gray;
    padding: 5px;
  }
  span:nth-child(4){
      border: 1px solid gray;
      border-left: 0px;
      padding: 5px;
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* Set to your desired background color */
  z-index: 1;
}
.ml-auto { margin-left: auto !important;}
.mr-auto { margin-right: auto}
.mt-auto { margin-top: auto}
.mb-auto { margin-bottom: auto}
.mt-20 { margin-top: 20px}
.mr-20 { margin-right: 20px}
.mr-10 { margin-right: 10px}
.mb-20{ margin-bottom: 20px !important;}
.mb-50{ margin-bottom: 50px !important;}
.m-0 { margin: 0}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
input[type="submit"],
button {
  cursor: pointer;
}
img {
  vertical-align: top;
  border-style: $d_none;
}
button:focus,
textarea:focus,
input:focus,
select {
  outline: $d_none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../assets/images/select-down-arrow.svg") no-repeat 95%;
}
textarea,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
select{
  color: var(--black-color);
  font-family: var(--font-family);
}
::placeholder { color: #999999}
input[type="date"]::-webkit-calendar-picker-indicator{
  background: $transparent;
  color: $transparent;
  cursor: pointer;
  height: auto;
  @include position_abs_left;
  right: 0;
  bottom: 0;
  width: auto;
}
*:focus {outline: $d_none;}
.transition{ @include transition;}
#wrapper {
  width: $w_100;
  position: $position_rel;
  overflow: $hidden;
}
.container{
  width: $w_100;
  max-width: 1140px;
  padding: 0 15px;
  margin: $margin_auto;
}
.container.fluid{
  max-width: $d_none;
  padding: 0 40px;
  @media (max-width: $breakpoint_tablet) {
    padding: 0 15px;
  }
}
.container:after {
  display:block;
  clear: both;
  content: '';
}
.position_abs_right {
  @include position_abs_right;
}
.position_abs_left {
  @include position_abs_left;
}
.relative{position: relative}
.row { margin: 0 -15px;}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  @media (max-width: 800px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}
.col-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.form-control,.search-wrapper {
  @include flex;
  align-items: center;
  height: 68px;
  background-color: transparent !important;
  border: 1px solid #999999 !important;
  font-size: 22px;
  border-radius: 4px;
  padding-left: 20px;
  color: var(--black-color);
  @include transition;
  &:focus {
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  @media (max-width: $breakpoint_laptop) {
    height: 50px;
    font-size: 16px;
  }
}
.form-control[type="file"] {
  padding-top: 12px;
}
textarea.form-control{
  height: 205px;
  padding-top: 19px;
  @media (max-width: $breakpoint_laptop) {
    height: 130px;
    padding-top: 12px;
  }
}
.search-wrapper {
  min-height: 68px !important;
  height: unset;
  @media (max-width: $breakpoint_laptop) {
    min-height: 50px !important;
    height: unset;
  }
}
textarea.form-input {
  height: 241px;
  padding-top: 15px;
}
.width-50 {
  width: 50% !important;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100% !important;
  }
}
table {
  background-color: transparent;
  max-width: 100%;
  border-collapse: collapse;
}
th {
  text-align: left;
}
table {
  width: 100%;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
  line-height: 1.5;
  vertical-align: middle;
}
table tr {
  box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.12);
  &.no-shadow {
    box-shadow: none;
    td, th { border: none !important; padding: 0 !important;}
  }
}
table {
  tbody{
    tr{
      &:nth-child(2n) {
        td {
          background: #fff;
        }
      }
      &:nth-child(4n) {
        td {
          background: #f7f7f7;
        }
      }
    }
  }
}
table thead tr th {
  white-space: nowrap;
  border-bottom: 1px solid #999999 !important;
  border-top: none !important;
  text-align: left;
  background: #F7F7F7;
  text-transform: capitalize;
  &.description {
    width: 40%;
  }
}
.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #666666;
  color: #272727;
}
table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td {
  border-bottom: 0 none;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 18px 30px;
  font-size: 16px;
  color: #292929;
  @media (max-width: $breakpoint_laptop) {
    font-size: 13px;
    padding: 11px 10px;
  }
}

#root {overflow: hidden;}
button { font-family: var(--font-family);}
.search-wrapper {
  & > input {
    font-size: 24px;
    padding-left: 17px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 16px;
    }
  }
}
.optionListContainer ul li {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 24px;
  line-height: 29px;
  @media (max-width: $breakpoint_laptop) {
    font-size: 15px;
    padding: 5px 17px;
  }
}
.chip { background: var(--theme-color) !important}
.optionListContainer ul li input {
  display: none;
}
.optionListContainer ul li:before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 4px;
  margin-right: 10px;
}
.optionListContainer ul li.selected:before {
  background: var(--theme-color);
  border-color: var(--theme-color);
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 15px;
  line-height: 1.2;
  padding-left: 1.5px;
  color: #fff;
}
.optionListContainer ul li.highlight { color: #000000;}
.highlightOption { background: none !important;}
.optionListContainer ul li:hover {background: #f6f6f6 !important; color: #000}
.react-calendar__tile--active {
  background: linear-gradient(180.04deg, #101828 1.28%, #0D1B37 99.81%, #0A1E46 99.96%) !important;
  color: #FFFFFF;
  border-radius: 100% !important;
}
.react-date-picker__calendar { width: 300px !important; z-index: 3 !important;}
.react-calendar__tile { height: 39px; border-radius: 8px;}
abbr[title] {
  text-decoration: none;
  font-family: var(--font-family);
}
.react-calendar__month-view__weekdays__weekday { padding: 0.5em 0 !important}
.react-calendar__navigation { margin-bottom: 0 !important}
.react-calendar__tile--now {
  background: var(--theme-color) !important;
  position: relative;
  color: #FFFFFF;
}
.react-calendar {
  border: none !important;
  box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  overflow: hidden;
}
.add-icon {
  margin-left: 10px;
  cursor: pointer;
  @media (max-width: $breakpoint_laptop) {
    height: 20px;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #555;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.4);
}
.sidebar {
  margin-right: 0 !important;
  border-right: none !important;
  min-width: 100% !important;
  width: 100% !important;
  height: calc(100vh - 350px);
  @media (max-width: $breakpoint_laptop) {
    height: calc(100vh - 260px);
  }
  @media (max-width: $breakpoint_mini_laptop) {
    height: calc(100vh - 250px);
  }
  overflow: auto;
  .sidebar-inner {
    background: none !important;
    overflow: unset !important;
    .menu-anchor {
      height: unset !important;
      span {
        display: flex;
        align-items: center;
        &.menu-icon {
          width: unset;
          min-width: unset;
          height: unset;
          line-height: unset;
          border-radius: 0;
          margin-right: 0;
        }
      }
    }
    .sub-menu {
      &.open {
        background: rgba(255,255,255,0.1);
        border-radius: 4px;
      }
      .sub-menu-content {
        background: none !important;
        overflow: hidden !important;
        ul {
          li {
            margin-bottom: 0;
            margin-top: 10px;
            @media (max-width: $breakpoint_laptop) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
.form-group {
  margin-bottom: 0;
  width: 100%;
  .links {
    font-size: 16px;
    margin-left: 40px;
    color: var(--theme-color);
    img {
      margin-right: 10px;
      transform: rotate(45deg);
    }
  }
}
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
}
.section-title {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 22px;
  font-size: 28px;
  @media (max-width: $breakpoint_laptop) {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
.leads-title{
  margin-top: 40px;
}
.accordion {
  border: none !important;
  border-radius: 0 !important;
}
.accordion__button {
  font-size: 24px;
  background: transparent !important;
  padding: 25px 18px !important;
  font-weight: 500;
  color: #000 !important;
  display: flex;
  align-items: center;
  @media (max-width: $breakpoint_laptop) {
    padding: 12px 18px !important;
    font-size: 16px !important;
  }
  &:before {
    position: absolute;
    right: 15px;
    top: 31px;
    @media (max-width: $breakpoint_laptop) {
      top: 17px;
    }
  }
  img {
    margin-left: 10px;
    @media (max-width: $breakpoint_laptop) {
      width: 15px;
    }
  }
}
.accordion__button:hover { background: transparent}
.accordion__item {
  background: #FFFFFF;
  box-shadow: 0 30px 72px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 45px;
  position: relative;
  @media (max-width: $breakpoint_laptop) {
    margin-bottom: 30px;
  }
  @media (max-width: $breakpoint_miniX_laptop) {
    margin-bottom: 20px;
  }
}
.accordion__item + .accordion__item { border-top: none !important}
.accordion__panel { padding-top: 0 !important;}
.under-link {
  color: #0A40FC;
  text-decoration: underline;
}
.inner-form-title  {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 28px;
  @media (max-width: $breakpoint_laptop) {
    font-size: 22px;
  }
}
.react-date-picker__inputGroup {
  min-width: 80px !important;
  margin-left: 10px;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 12px;
}
.react-calendar { padding: 10px;}
.react-calendar button { padding: 0 !important;}
.react-time-picker__wrapper { border: none !important;}

.mandatory{
  border: 1px solid rgba(250, 127, 127, 0.8) !important;
}
.mandatory-multi{
  .search-wrapper{  
    border: 1px solid rgba(250, 127, 127, 0.8) !important;
  }
}
.pb-150{
  padding-bottom: 150px;
}
.uper_title{
  border: 1px solid #999999;
  font-size: 20px;
  border-bottom: none;
  padding: 10px 0;
  width: 100%;
  background: #EBEBEB;
  font-weight: 600;
  max-width: 800px;
}
.datatable_wrap {
  position: relative !important;
  &.table-3 {
    div {
      div {
        &[role="table"] {
          [role="rowgroup"] {
            &:first-child {
              [role="row"] {
                & > div {
                  padding: 20px 10px;
                  & > div {
                    & > div {font-size: 14px;}
                  }
                }
              }
            }
            [role="row"] {
              & > div {
                &:first-child {
                  min-width: 200px;
                  text-align: left;
                  padding: 0;
                & > div {
                  & > div {
                    & > div {
                      border-bottom: none;
                      padding: 10px 7px;
                      margin: 0;
                      border-right: 1px solid #999;
                      &:last-child { border-right: none;}
                    }
                  }
                }
                }
              }
            }
          }
        }
      }
    }
  }
  &.table-4 {
    div {
      div {
        &[role="table"] {
          [role="rowgroup"] {
            &:first-child {
              [role="row"] {
                & > div {
                  padding: 20px 10px;
                  & > div {
                    & > div {font-size: 14px;}
                  }
                }
              }
            }
            [role="row"] {
              & > div {
                &:first-child {
                  min-width: 200px;
                  text-align: left;
                  padding: 0;
                & > div {
                  & > div {
                    & > div {
                      border-bottom: none;
                      padding: 10px 7px;
                      margin: 0;
                      border-right: 1px solid #999;
                      &:last-child { border-right: none;}
                    }
                  }
                }
                }
              }
            }
          }
        }
      }
    }
  }
  div {
    &[role="table"] {
      background-color: transparent;
      [role="rowgroup"] {
        &:first-child {
          [role="row"] {
            background: #9C8831;
            & > div {
              color: #fff;
              padding: 14px 20px;
              border-left: 1px solid #EBEBEB;
              &:first-child { border-left-color: #999999}
              & > div {
                overflow: unset;
                & > div {
                  overflow: unset;
                  white-space: unset;
                  text-overflow: unset;
                  text-align: center;
                  font-weight: 400;
                  font-size: 16px;
                }
              }
            }
          }
        }
        [role="row"] {
          border: none;
          min-height: unset;
          & > div {
            border: 1px solid #999999;
            padding: 0 0;
            border-right: none;
            border-bottom: none;
            color: #000000;
            font-size: 16px;
            text-align: center;
            &:last-child { border-right: 1px solid #999999}
            & > div {
              width: 100%;
              & > div {
                div {
                  border-bottom: 1px solid #999999;
                  padding-bottom: 10px;
                  padding-top: 10px;
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
          .table-content {
            color: #07070C;
            font-family: 'Nunito', sans-serif;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.team-name {
  & > div {
    width: 50%;
    &:first-child { display: flex; align-items: center; justify-content: center;}
    // &:last-child {
    //   div {
    //     padding-bottom: 0 !important;
    //     margin-bottom: 0 !important;
    //     border-bottom: none !important;
    //   }
    // }
  }
}
.listing_table_wrapper {
  display: flex;
  flex-direction: column;
  &.table_2 {
    ul {
      li {
        &:first-child { 
          width: 200px !important;
          max-width: 200px !important;
          min-width: 200px !important;
        }
      }
    }
  }
  ul {
    background: #EBEBEB;
    border: 1px solid #999999;
    border-top: none;
    &:first-child {
      background: #9C8831;
      li {
        color: #fff;
        //border-right: 1px solid #EBEBEB;
      }
    }
    li {
      &:first-child {
        width: 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
        justify-content: flex-start;
        padding-left: 60px;
      }
      // width: 20%; 
      width: 100px;
      max-width: 100px;
      min-width: 100px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.caseId{
  display: flex;
  p {
    font-weight: bold;
    margin-left: 10px;
  }
}
.responsive_table {
  max-height: 500px;
  overflow: auto;
  // width: max-content;
  // position: relative;
  // padding-right: 10px;
  &.table_1 {
    max-width: 715px;
    & > div {
      width: 700px;
    }
  }
  &.table_2 {
    max-width: 815px;
    & > div {
      width: 800px;
    }
  }
  
  & > div {
    overflow: unset !important;
    width: 800px;
    & > div {
      max-width: 100%;
    }
  }
  
  .listing_table_wrapper{
    &.table_4 {
      width: 2360px;
      ul {
        li {
          &:first-child { width: 25%;}
          width: 100px;
          max-width: 100px;
          min-width: 100px;
        }
      }
  }
    &.table_3 {
      width: 1800px;
      ul {
        li {
          &:first-child { 
            width: 200px !important;
            max-width: 200px !important;
            min-width: 200px !important;
          }
        }
      }
    }
  }
}
.request-amount-div{
  h1{
    border-bottom: 1px solid #666666;
    font-weight: bold;
    padding: 11px 10px;
    font-size: 13px;
  }
  span{
    padding: 11px 10px;
  }
}
.p-0{
  padding: 0px !important;
}
.px-2{
  padding-left: 10px;
  padding-right: 10px;
}
.mx-250{
  max-width: 250px !important;
}
.done-icon{
  position: absolute;
  right:8px;
  bottom:8px;
  cursor: pointer;
}
.client-approved-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  font-weight: bold;
  font-size: 18px;
  @media (max-width: $breakpoint_mobile_portrait) {
    font-size: 14px;
    gap: 15px;
  }
  & > div {
    &:last-child { white-space: nowrap}
  }
}
.attachments-list{
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #9C8831;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px;
}
.dropdown-container .optionListContainer{
  max-height: 200px; /* Adjust as needed */
  overflow-y: scroll;
}
.quill-file-attachment{
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-transform: none;
  font-weight: bold;
  border: 1px solid #cccccc !important;
  border-top-width: 0px;
}
.login-logo-div{
  display: flex;
  justify-content: center;
  img{
    max-width: 200px;
  }
}
.approved-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  @media (max-width: $breakpoint_mobile_portrait) {
    font-size: 14px;
    gap: 15px;
  }
  & > div {
    &:last-child { white-space: nowrap}
  }
}
.maxed{
  max-height: 60vh !important;
  overflow-y: scroll !important;
}
.ml-20{
  margin-left: 20px;
}
.ml-10{
  margin-left: 10px;
}
.ql-container.ql-snow{
  min-height: 200px !important;
}
.gold-font{
  color: #a28732;
}
.bg-white{
  background-color: white !important;
}
.latest-comment {
  width:50%;
  padding: 15px 20px 20px;
  background: #F7F7F7;
  border-radius: 4px;
  max-height: 655px;
  overflow: auto;
  @media (max-width: $breakpoint_laptop) {
    max-height: 500px;
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #DBDBDB;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--theme-color);
  }
  h3 {
    font-size: 20px;
    color: #9C8831;
    margin: 0 0 20px;
    font-weight: 700;
    @media (max-width: $breakpoint_laptop) {
      font-size: 18px;
    }
  }
  & > ul {
    & > li {
      margin: 0 0 20px;
      padding-left: 35px;
      position: relative;
      @media (max-width: $breakpoint_laptop) {
        padding-left: 25px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #9C8831;
        @media (max-width: $breakpoint_laptop) {
          width: 10px;
          height: 10px;
        }
      }
      h6 {
        font-weight: 700;
        font-size: 20px;
        color: #000000;
        margin: 0 0 5px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
        }
      }
      small {
        display: block;
        color: #999999;
        font-size: 20px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
        }
      }
      span {
        font-size: 20px;
        color: #000000;
        display: block;
        margin-bottom: 8px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
        }
      }
    }
  }
}
// .no-p-styling{
//   ul {
//     li {
     
//       &:before {
//         content:'O';
//         // position: absolute;
//         // left: 0;
//         // top: 7px;
//         // width: 15px;
//         // height: 15px;
//         // border-radius: 100%;
//         // background: #9C8831;
//       }
//     }
//   }
// }

.input-font{
  font-size: 14px;
  font-weight: 500;
  margin-left: 26px;
}
.my-unique-input-group {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.my-unique-minus {
  margin-right: -30px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  width: 30px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  border: 1px solid gray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.my-unique-input {
  width: 150px;
  height: 30px;
  border: none;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
  border-radius: 5px;
}
.my-unique-plus {
  margin-left: -30px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  border: 1px solid gray;
  width: 30px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mb-10{
  margin-bottom: 10px;
}
.my-20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.py-20{
  padding: 20px 0px;
}
.py-10{
  padding: 10px 0px;
}
.date-wrapper {
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column;
  }
  & > .flex {
    @media (max-width: $breakpoint_mobile_portrait) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      & > div {
        width: 100%;
        padding: 0;
        margin-bottom: 15px;
        &.disbursed {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
}
.centered-table{
  text-align: center;
  th {
    text-align: center;
  }
  td {
    text-align: center;
    .status{
      justify-content: center;
    }
    div{
      justify-content: center;
    }
  }
}
.br-0{
  border-radius: 0px;
}
.brr-0{
  border-radius: 0px;
}
.brl-0{
  border-radius: 0px;
}
.none-pointer-events{
  pointer-events: none !important;
}
.table-1 {
  overflow-x: auto;
  .sticky-table {
    width: 715px;
    .sticky-table-row {
      .sticky-table-cell {
        // &:first-child {
        //   width: 200px;
        //   max-width: 200px;
        // }
      }
    }
  }
}
.table-2 {
  overflow-x: auto;
  .responsive_table {
    max-width: 815px;
  }
  .sticky-table {
    width: 700px;
    .sticky-table-row {
      .sticky-table-cell {
        // &:first-child {
        //   width: 200px;
        //   max-width: 200px;
        // }
      }
    }
  }
}

.table-3 {
  overflow-x: auto;
  .sticky-table {
    width: 1800px;
    .sticky-table-row {
      .sticky-table-cell {
        // &:first-child {
        //   width: 200px;
        //   max-width: 200px;
        // }
      }
    }
  }
}
.table-4 {
  overflow-x: auto;
  .sticky-table {
    width: 1300px;
    .sticky-table-row {
      .sticky-table-cell {
        // &:first-child {
        //   width: 200px;
        //   max-width: 200px;
        // }
      }
    }
  }
}
.sticky-table {
  .sticky-table-row {
    &:first-child {
      .sticky-table-cell {
        background-color: #9C8831;
        font-size: 10px;
        color: #fff;
        line-height: 1.2;
        padding: 14px 20px;
        border-right: 1px solid #EBEBEB !important;
      }
    }
    .sticky-table-cell {
      font-size: 12px !important;
      white-space: initial;
      width: 100px;
      max-width: 100px;
      min-width: 100px;
      padding: 0 0;
      border-right: 1px solid #999999 !important;
      text-align: center;
      vertical-align: middle;
      border-bottom: 1px solid #999999 !important;
      & > div {
        & > div {
          border-bottom: 1px solid #999999;
          color: #000000;
          font-size: 12px;
          text-align: center;
          padding-bottom: 0;
          padding-top: 0;
          height: 46px;
          line-height: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &:last-child { 
            border-bottom: none !important;}   
        }
      }
    }
  }
}
.team-name {
  div {
    padding: 0 !important;
    border-left: 1px solid #999999;
    &:last-child {
      border-bottom: none !important;
    }
    div {
      font-size: 14px;
      border-left: none;
      border-bottom: 1px solid #999999;
      padding: 10px 0 !important;
    }
  }
}
.fixed-footer-1{
  position: absolute;
  background: #9C8831;
  bottom: 65px;
  left: 0px;
  border-right: 1px solid #999999;
  z-index: 3;
}
.fs-18{
  font-size: 18px;
}
.fixed-footer-2{
  position: absolute;
  background: #EBEBEB;
  bottom: 0px;
  left: 0px;
  border-right: 1px solid #999999;
  border-bottom: 1px solid #999999;
  z-index: 1;
}
.fixed-footer-3{
  position: absolute;
  background: #9C8831;
  bottom: 0px;
  left: 0px;
  border-right: 1px solid #999999;
  border-bottom: 1px solid #999999;
  z-index: 1;
}
.clear-all-icon{
  width: 30px;
  height: 30px;
  margin-left: auto;
  cursor: pointer;
}
.mr-10{
  margin-right: 10px;
}