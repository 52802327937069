@import "../../assets/scss/mixin";
.pagination-wrapper {
  margin: 26px 0 15px;
  padding-right: 30px;
  @media (max-width: $breakpoint_laptop) {
    padding-right: 15px;
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    margin-bottom: 0;
  }
  ul {
    margin: 0 -8px;
    @media (max-width: $breakpoint_laptop) {
      margin: 0 -3px;
    }
    li {
      padding: 0 8px;
      @media (max-width: $breakpoint_laptop) {
        padding: 0 3px;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #EBEBEB;
        border-radius: 4px;
        width: 47px;
        height: 46px;
        font-size: 16px;
        padding: 0;
        background: transparent;
        @media (max-width: $breakpoint_laptop) {
          width: 35px;
          height: 35px;
          font-size: 12px;
        }
        @media (max-width: $breakpoint_miniX_laptop) {
          width: 30px;
          height: 30px;
          font-size: 11px;
        }
        @include transition;
        &.loop-dots {
          border: none;
          width: auto;
          align-items: flex-end;
          font-size: 35px;
          line-height: 1;
          white-space: nowrap;
          color: #EBEBEB !important;
          &:hover {
            background: transparent;
            box-shadow: none;
          }
        }
        &.picon {
          border: none;
          width: auto;
          @media (max-width: $breakpoint_laptop) {
            padding: 0 5px;
          }
          @media (max-width: $breakpoint_miniX_laptop) {
            img {
              height: 14px;
            }
          }
          &.next {
            transform: scaleX(-1);
          }
          &.disabled {
            opacity: 0.6;
            pointer-events: none;
          }
          &:hover {
            background: transparent;
            box-shadow: none;
            img {
              filter: sepia(1);
            }
          }
        }
        &.active,&:hover {
          background: var(--theme-color);
          border: none;
          color: #FFFFFF;
          box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
.entries-dropdown {
  padding-left: 30px;
  margin: 15px 0 0;
  @media (max-width: $breakpoint_laptop) {
    padding-left: 15px;
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    display: none;
  }
  span {
    margin-right: 22px;
    font-size: 20px;
    color: #000000;
    @media (max-width: $breakpoint_laptop) {
      margin-right: 16px;
      font-size: 15px;
    }
    @media (max-width: $breakpoint_miniX_laptop) {
      font-size: 13px;
    }
  }
  select {
    color: #000000;
    width: 100px;
    height: 46px;
    border-radius: 4px;
    border: 2px solid #EBEBEB;
    font-size: 16px;
    padding-left: 16px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 12px;
      width: 70px;
      height: 35px;
    }
  }
}