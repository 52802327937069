@import "../../../assets/scss/mixin";
.case-accordion {
  .accordion__button {
    background: var(--theme-color) !important;
    border-radius: 10px;
    color: #FFFFFF !important;
  }
  .accordion__panel { padding-top: 20px !important}
  .document-wrapper {
    @media (max-width: $breakpoint_mobile_portrait) {
      flex-direction: column;
    }
    .cases-doc-list {
      padding-left: 0;
      ul {
        max-height: unset;
        li {
          padding-right: 20px;
          @media (max-width: $breakpoint_mobile_portrait) {
            & > .flex {
              flex-direction: column-reverse;
              span { margin-left: auto}
              section { margin-bottom: 10px;}
            }
          }
          &.title { border-bottom: none; padding-bottom: 0}
        }
      }
    }
  }
}
.single-right-wrap {
  width: 50%;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100%;
  }
  .latest-comment { width: 100%;}
}
.comments-wrapper {
  margin-bottom: 40px;
  p {
    margin: 0 0 15px;
    line-height: 19px;
    strong { display: block}
  }
  textarea { margin-bottom: 19px;}
}
.file_name{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.files-upload {
  margin: 5px 0 0;
  button {
    margin: 0;
    white-space: nowrap;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 26px 0;
    background: #F7F7F7;
    width: 100%;
    justify-content: center;
    border: 1px dashed #9C8831;
    border-radius: 12px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 16px;
    }
    img {
      margin-right: 14px;
      width: 40px;
      height: auto;
      margin-left: 0 !important;
      @media (max-width: $breakpoint_laptop) {
        margin-right: 8px;
        width: 30px;
      }
    }
  }
}
.remove-icon {
  margin: 0 !important;
  width: 24px !important;
  flex: 0 0 24px !important;
  position: absolute;
  right:34px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: $breakpoint_laptop) {
    margin: 0 -6px 0 5px !important;
  }
}
.caseinput-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .caseinput {
    display: flex;
    margin-bottom: 5px;
    small {
      border: 1px solid #999999;
      border-radius: 4px 0 0 4px;
      border-right: none;
      height: 60px;
      display: flex;
      width: 160px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      @media (max-width: $breakpoint_laptop) {
        font-size: 14px;
        height: 40px;
        width: 120px;
      }
    }
    input {
      width: calc(100% - 160px);
      margin-left: auto;
      height: 60px;
      font-size: 18px;
      padding-left: 0;
      &:focus { box-shadow: none}
      border-left: none !important;
      border-radius: 0 4px 4px 0;
      @media (max-width: $breakpoint_laptop) {
        font-size: 14px;
        height: 40px;
        width: calc(100% - 120px);
      }
      @media (max-width: $breakpoint_mobile_portrait) {
        padding-left: 5px;
      }
    }
  }
}
.rejectionReason {
  display: block;
  text-align: right;
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
}
.document-wrapper {
  @media (max-width: $breakpoint_mobile_portrait) {
    & > div { width: 100% !important;}
  }
}