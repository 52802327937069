@import './_reset.scss';
h1 { font-size: 26px;}
h2 { font-size: 24px;}
h3 { font-size: 22px;}
h4 { font-size: 20px;}
h5 { font-size: 18px;}
h6 { font-size: 16px;}
h1,h2,h3,h4,h5,h6 {
  a {
    color: var(--black-color);
  }
}
p {
  line-height: 25px;
  margin: 0 0 10px;
  a {
    color: var(--black-color);
  }
}
a {
  color: var(--black-color);
  text-decoration: none;
}
a {
  &:hover, &:focus, &:active{
    outline: none;
    text-decoration: none;
  }
}
.cursor-pointer{
  cursor: pointer !important;
}
.mt-5{
  margin-top: 5px !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mt-30{
  margin-top: 30px;
}
.unflex{
  display: block !important;
}
.announcement tr th,
.announcement tr td { padding: 10px 20px !important;}
.scheduler-wrapper {
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column-reverse;
  }
  .calender-date-wrap {
    width: calc(100% - 350px);
    @media (max-width: $breakpoint_laptop) {
      width: calc(100% - 300px);
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      width: 100%;
    }
    .dx-scheduler-header {
      background: #F6F6F6;
      border: none;
    }
    .dx-scheduler-header-panel {
      border-top: 1px solid rgba(221,221,221,1) !important;
      margin-top: 0;
    }
    .dx-scheduler-header-panel-cell { background: #FFFFFF;}
  }
  .calender-user {
    margin-top: 56px;
    background: #fff;
    width: 350px;
    @media (max-width: $breakpoint_laptop) {
      width: 300px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      margin: 20px auto;
    }
    .react-calendar {
      border: 1px solid rgba(221,221,221,.6) !important;
      border-top: none !important;
      box-shadow: none;
      border-radius: 0;
    }
    & > section {
      background: #FFFFFF;
      border: 1px solid rgba(221,221,221,.6);
      padding: 16px 25px;
      figure {
        margin: 0;
        display: flex;
        align-items: center;
        img {
          margin-right: 16px;
          width: 58px;
          height: 58px;
          object-fit: cover;
        }
        figcaption {
          h6 {
            margin: 0;
            font-weight: 500;
          }
          span {
            color: #999999;
          }
        }
      }
    }
    .jump-date {
      padding: 15px 15px 0;
      h2 {
        margin: 0 0 0;
        font-size: 18px;
        font-weight: 600;
      }
      ul {
        li {
          width: 14%;
          margin: 10px 0 0;
          span {
            display: flex;
            width: 100%;
            cursor: pointer;
            align-items: center;
            height: 44px;
            border-radius: 5px;
            justify-content: center;
            @media (max-width: $breakpoint_laptop) {
              height: 37px;
            }
            &.active {
              background: #9C8831;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.f-14{
  font-size: 14px !important;
}
.w-auto{
  max-width: 100% !important;
}
.w-200{
  width: 200px;
}
.w-300{
  width: 300px;
}
.w-160{
  width: 160px;
}
.file-input-label {
  display: inline-block;
  // padding: 10px;
  // background-color: #f2f2f2;
  color: #333;
  // border-radius: 4px;
  cursor: pointer;
  margin: 0px !important;
}

.file-input-label img {
  width: 30px;
}
.bottom{
  display: flex;
  justify-content: end;
}
.bold{
  font-weight: bold;
}
.w-70{
  min-width: 70px;
}
.underline{
  text-decoration: underline;
}
.me-10{
  margin-right: 20px;
}
.submit-button-case{
  padding: 10px 20px;
  border-radius: 20px;
  border-width: 0px;
  background-color: #a68c39;
  color:white
}
.align-bottom{
  align-items: end;
}
.sort-icon{
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
  margin-left: 5px;
}
.ml-0{
  margin-left: 0px !important;
}