@import "../../assets/scss/mixin";
.panel-wrapper {
  background: #F6F6F6;
  .sidebar-wrapper {
    position: fixed;
    padding: 0 20px;
    left: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    z-index: 1000;
    background: #131000;
    @include transition;
    &.active {
      transform: translateX(-300px);
      @media (max-width: $breakpoint_mini_laptop) {
        transform: translateX(0);
      }
    }
    @media (max-width: $breakpoint_mini_laptop) {
      transform: translateX(-300px);
    }
    @media (max-width: $breakpoint_mini_laptop) {
      z-index: 99999;
    }
    .sidebar-content {
      figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #999999;
        font-size: 12px;
        margin: 0 0 35px;
        padding: 20px 0 16px;
        border-bottom: 1px solid #EBEBEB;
        @media (max-width: $breakpoint_laptop) {
          margin: 0 0 25px;
          padding: 10px 0 12px;
        }
        @media (max-width: $breakpoint_tablet) {
          padding: 3px 0 5px;
          margin: 0 0 15px;
        }
        img{
          max-width: 132px;
          @media (max-width: $breakpoint_laptop) {
            max-width: 110px;
          }
        }
      }
      ul {
        li {
          margin-bottom: 24px;
          @media (max-width: $breakpoint_laptop) {
            margin-bottom: 8px;
          }
          &.sub-menu {
            & > a.menu-anchor {
              align-items: unset;
              &.active {
                background: var(--theme-color);
              }
              & > span {
                border-radius: 0;
                padding-left: 0;
                padding-right: 0;
                &:first-child{
                  padding-left: 15px;
                  border-radius: 4px 0 0 4px;
                  @media (max-width: $breakpoint_laptop) {
                    padding-left: 13px;
                  }
                }
                &:last-child{
                  padding-right: 15px;
                  border-radius: 0 4px 4px 0;
                  @media (max-width: $breakpoint_laptop) {
                    padding-right: 13px;
                  }
                }
              }
            }
          }
          &.active {
            a {
              & > span {
                background: var(--theme-color);
              }
            }
          }
          a {
            padding: 0;
            &:hover {
              background: transparent;
              & > span {
                background: var(--theme-color);
              }
            }
            & > span {
              &.active {
                background: var(--theme-color);
              }
              display: flex;
              font-size: 22px;
              padding: 14px 15px 13px;
              color: #ffffff;
              border-radius: 4px;
              text-transform: capitalize;
              align-items: center;
              @include transition;
              img {
                margin-right: 16px;
                height: 20px;
              }
              @media (max-width: $breakpoint_laptop) {
                font-size: 14px;
                padding: 10px 13px 9px;
              }
            }
          }
        }
      }
      .logout-btn {
        width: 100%;
        max-width: 229px;
        display: flex;
        color: #EBEBEB;
        position: absolute;
        bottom: 50px;
        left: 32px;
        height: 60px;
        border-radius: 4px;
        background: var(--theme-color);
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        transition: all 0.4s ease-in-out;
        &:hover { background-color: #fff; color: var(--theme-color)}
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
          // bottom: 20px;
          height: 45px;
        }
      }
    }
  }
  .panel-content-wrapper {
    width: calc(100vw - 300px);
    margin-left: 300px;
    &.active {
      width: calc(100vw);
      margin-left: 0;
    }
    @media (max-width: $breakpoint_mini_laptop) {
      width: calc(100vw);
      margin-left: 0;
    }
    @media (max-width: $breakpoint_mini_laptop) and (min-width: $breakpoint_tablet) {
      &.active {
        width: calc(100vw - 300px);
        margin-left: 300px;
        }
    }
    @include transition;
    width: calc(100vw - 300px);
    margin-left: 300px;
    position: relative;
    .panel-content {
      padding: 50px;
      width: 100%;
      min-height: calc(100vh - 100px);
      @media (max-width: $breakpoint_laptop) {
        padding: 30px;
        min-height: calc(100vh - 66px);
      }
      @media (max-width: $breakpoint_tablet) {
        padding: 15px;
        min-height: calc(100vh - 48px);
      }
    }
  }
}
.menu-btn {
  position: absolute;
  left: 20px;
  top: 42px;
  padding: 0;
  z-index: 99999;
  background: transparent;
  border: none;
  // display: none;
  @media (max-width: $breakpoint_laptop) {
    top: 27px;
  }
  @media (max-width: $breakpoint_tablet) {
    top: 23px;
  }
  // &.active { pointer-events: none;}
}
.announcement-modal {
  p {
    text-align: left !important;
    line-height: 24px;
  }
  .title-wrap {
    margin-bottom: 30px;
    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 22px;
    }
  }
}
.announcement-button-wrap {
  justify-content: space-between;
  img {
    cursor: pointer;
    &.nextIcon {
      transform: rotate(180deg);
    }
  }
}