@import "../../../../assets/scss/mixin";
.add-product-form {
  .modal-forms {
    h2 {
      margin-bottom: 40px;
      //text-align: left;
      @media (max-width: $breakpoint_laptop) {
        margin-bottom: 20px;
      }
    }
  }
}
.product-form-list {
  .form-control {
    max-width: 293px;
    height: 50px;
    @media (max-width: $breakpoint_laptop) {
      max-width: 240px;
      font-size: 13px;
      padding-left: 12px;
    }
  }
  label {
    margin: 5px 0 5px 30px;
    @media (max-width: $breakpoint_laptop) {
      margin-left: 20px;
    }
    span { padding-left: 30px;}
  }
  .add-extra-list {
    margin-left: auto;
    &.del {
      width: 50px;
      height: 50px;
      border: 1px solid #999999;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 5px;
      button { padding: 0; margin: 0}
    }
    button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 10px;
      img {
        height: 24px;
        @media (max-width: $breakpoint_laptop) {
          height: 25px;
        }
      }
    }
  }
}