@import "../../assets/scss/mixin";
.search-wrap {
  background: #FFFFFF;
  box-shadow: 0 30px 72px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 20px 30px;
  @media (max-width: $breakpoint_laptop) {
    padding: 15px 20px;
  }
  @media (max-width: $breakpoint_miniX_laptop) {
    padding: 7px;
  }
  @media (max-width: $breakpoint_tablet) {
    padding: 10px;
  }
  &.case-search {
    .user-search {
      max-width: 400px;
      @media (max-width: $breakpoint_laptop) {
        max-width: 300px;
      }
      @media (max-width: $breakpoint_miniX_laptop) {
        max-width: 250px;
      }
    }
  }
  &.entities-wrap {
    .search-filter {
      &.date {
        margin-right: 0;
        width: 300px;
        @media (max-width: $breakpoint_miniX_laptop) {
          width: 200px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          width: 100% !important;
        }
      }
    }
  }
  &.mega-search {
    padding: 10px;
    .user-search {
      max-width: 344px;
      @media (max-width: $breakpoint_laptop) {
        max-width: 240px;
        input {
          font-size: 15px;
        }
      }
      @media (max-width: $breakpoint_miniX_laptop) {
        max-width: 200px;
      }
      @media (max-width: $breakpoint_tablet) {
        margin-bottom: 20px;
      }
      @media (max-width: $breakpoint_mobile_landscape) {
        margin-bottom: 10px;
      }
    }
    .search-filter {
      margin-right: 11px;
      @media (max-width: $breakpoint_laptop) {
        margin-right: 5px;
      }
      img{
        left: 12px;
        width: 20px;
        @media (max-width: $breakpoint_laptop) {
          width: 15px;
          left: 10px;
        }
        @media (max-width: $breakpoint_miniX_laptop) {
          width: 12px;
          left: 6px;
        }
      }
      select {
        width: 220px;
        font-size: 15px;
        padding-left: 40px;
        @media (max-width: $breakpoint_laptop) {
          width: 190px;
          padding-left: 32px;
          font-size: 14px;
        }
        @media (max-width: $breakpoint_miniX_laptop) {
          height: 42px;
          font-size: 12px;
          width: 170px;
          padding-left: 22px;
        }
      }
    }
    .search-btn {
      @media (max-width: $breakpoint_laptop) {
        font-size: 16px;
        padding: 0 30px;
      }
      @media (max-width: $breakpoint_miniX_laptop) {
        font-size: 13px;
        padding: 0 20px;
      }
    }
  }
  .user-search {
    position: relative;
    width: 100%;
    max-width: 562px;
    display: flex;
    align-items: center;
    @media (max-width: $breakpoint_laptop) {
      max-width: 370px;
    }
    @media (max-width: $breakpoint_miniX_laptop) {
      max-width: 200px;
    }
    @media (max-width: $breakpoint_tablet) {
      max-width: 285px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      margin-bottom: 10px;
      max-width: 100% !important;
      & ~ .ml-auto {
        flex-direction: column;
        margin-left: unset !important;
        width: 100% !important;
      }
    }
    input {
      height: 68px;
      border: 1px solid #999999;
      border-radius: 4px;
      width: 100%;
      padding-left: 16px;
      color: #000;
      font-size: 24px;
      &::placeholder { color: #999999}
      @media (max-width: $breakpoint_laptop) {
        font-size: 16px;
        height: 55px;
      }
      @media (max-width: $breakpoint_miniX_laptop) {
          height: 42px;
          font-size: 13px;
          padding-left: 10px;
      }
      @media (max-width: $breakpoint_tablet) {
        font-size: 13px;
        height: 44px;
      }
    }
  }
}

.search-filter {
  @media (max-width: $breakpoint_mobile_portrait) {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.single-sort {
    @media (max-width: $breakpoint_miniX_laptop) {
      width: 220px;
    }
    select {
      width: 300px;
      font-size: 18px;
      @media (max-width: $breakpoint_miniX_laptop) {
        width: 220px;
        font-size: 14px;
        height: 42px;
      }
    }
    img {
      width: 15px;
      left: 10px;
    }
  }
  position: relative;
  margin-right: 24px;
  @media (max-width: $breakpoint_laptop) {
    margin-right: 15px;
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  &.date {
    .form-control {
      height: 68px;
      padding-left: 45px;
      @media (max-width: $breakpoint_laptop) {
        height: 55px;
        padding-left: 30px;
      }
      @media (max-width: $breakpoint_miniX_laptop) {
        height: 42px;
        font-size: 12px;
        padding-left: 20px;
      }
    }
  }
  img {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: $breakpoint_laptop) {
      width: 15px;
      left: 10px;
    }
    @media (max-width: $breakpoint_miniX_laptop) {
      width: 12px;
      left: 6px;
    }
  }
  select {
    cursor: pointer;
    padding-left: 51px;
    font-size: 21px;
    color: #999999;
    font-weight: 400;
    width: 300px;
    height: 68px;
    border: 1px solid #999999;
    border-radius: 4px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 16px;
      height: 55px;
      width: 230px;
      padding-left: 35px;
    }
    @media (max-width: $breakpoint_miniX_laptop) {
      height: 42px;
      font-size: 12px;
      width: 170px;
      padding-left: 30px;
    }
    @media (max-width: $breakpoint_tablet) {
      font-size: 13px;
      width: 180px;
      height: 44px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      width: 100% !important;
    }
  }
}

.react-date-picker__wrapper{
  border: none !important;
}

.search-submit-btn {
  // background-color: var(--theme-color);
  // color: #fff;
  height: 30px;
  border-radius: 4px;
  position: absolute;
  right: 16px;
  // top: 10px;
  // font-weight: 500;
  // font-size: 24px;
  // padding: 0 28px;
  display: flex;
  // border: none;
  // align-items: center;
  // justify-content: center;
  // @include transition;
  // &:hover { background-color: var(--black-color); color: var(--white-color)}
  // @media (max-width: $breakpoint_laptop) {
  //   height: 40px;
  //   right: 10px;
  //   top: 7px;
  //   font-size: 16px;
  //   padding: 0 17px;
  // }
  // @media (max-width: $breakpoint_miniX_laptop) {
  //   padding: 0 11px;
  //   font-size: 12px;
  //   height: 32px;
  //   right: 5px;
  //   top: 5px;
  // }
  // @media (max-width: $breakpoint_tablet) {
  //   height: 34px;
  //   font-size: 13px;
  //   padding: 0 12px;
  //   right: 5px;
  //   top: 5px;
  // }
}
.search-btn {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  background-color: var(--theme-color);
  color: #FFFFFF;
  padding: 0 58px;
  height: 68px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  @include transition;
  &:hover { background-color: var(--black-color); color: var(--white-color)}
  @media (max-width: $breakpoint_laptop) {
    font-size: 18px;
    padding: 0 40px;
    height: 55px;
  }
  @media (max-width: $breakpoint_miniX_laptop) {
    font-size: 13px;
    padding: 0 20px;
    height: 42px;
  }
  @media (max-width: $breakpoint_tablet) {
    font-size: 16px;
    padding: 0 25px;
    height: 44px;
  }
}
.team-dropdown {
  span {
    color: #000;
    font-weight: 500;
    font-size: 24px;
    margin-right: 17px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 18px;
    }
  }
  select {
    cursor: pointer;
    padding-left: 19px;
    font-size: 24px;
    color: #000;
    font-weight: 400;
    width: 300px;
    height: 68px;
    border: 1px solid #999999;
    border-radius: 4px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 16px;
      height: 55px;
      width: 230px;
    }
    @media (max-width: $breakpoint_tablet) {
      height: 44px;
    }
  }
}
.react-date-picker__button svg { width: 15px;}
.button-wrap {
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column-reverse;
    button { margin: 0 0 10px;}
  }
}
.modal-dialog {
  .fixed-button-wrap {
    left: 50%;
    width: 100%;
    max-width: 1060px;
    transform: translateX(-50%);
    @media (max-width: $breakpoint_laptop) {
      max-width: 980px;
    }
    @media (max-width: $breakpoint_tablet) {
      padding: 5px 30px;
      max-width: 100%;
      width: calc(100% - 25px);
    }
  }
}
.panel-content-wrapper {
  &.active {
    .fixed-button-wrap {
      width: calc(100% - 100px);
      @media (max-width: $breakpoint_laptop) {
        width: calc(100% - 60px);
      }
    }
  }
}
.fixed-button-wrap {
  position: fixed;
  width: calc(100% - 400px);
  bottom: 10px;
  right: 50px;
  background-color: #FFFFFF;
  @media (max-width: $breakpoint_laptop) {
    width: calc(100% - 360px);
    right: 30px;
  }
  @media (max-width: $breakpoint_mini_laptop) {
    width: calc(100% - 60px);
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: $breakpoint_tablet) {
    width: calc(100% - 30px);
  }
}