@import "../../../assets/scss/mixin";
.input-row{
  display: flex;
  align-items: baseline;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
  font-size:14px;
  background-color: white;
  border: 1px solid #9C8831;
  display: inline-block;
}
.inputfile + label * {
	pointer-events: none;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
  padding: 10px 30px;
}
.upload-btn{
  color: #EBEBEB;
  width: 120px;
  display: flex;
  border: 2px solid #9C8831;
  background: #9C8831;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  border-radius: 50px;
  margin-left: 20px;
}
.form-render{
  width: 70%; 
  margin: auto; 
  padding-top: 50px
}
.online-form {
  margin: 30px 0;
  display: flex;
  .form-online-box {
    width: 100%;
    border: 1px solid #999999;
    border-radius: 4px;
    & > h2 {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      margin: 0;
      img { margin-right: 5px;}
    }
    .online-inner-form {
      border-top: 1px solid #999999;
      padding: 20px;
      .cross-btn { margin: 0px 0px 0px 10px ;
      height: 20px; width: 20px;}
    }
  }
  .form-button-list {
    width: 100%;
    max-width: 370px;
    margin-left: 30px;
    @media (max-width: $breakpoint_laptop) {
      max-width: 300px;
    }
    ul {
      li {
        button {
          color: #FFFFFF;
          width: 100%;
          border: none;
          background: var(--theme-color);
          border-bottom: 1px solid #FFFFFF;
          font-size: 22px;
          padding: 10px 0;
          @include transition;
          &:hover { background: var(--theme-second-color)}
          @media (max-width: $breakpoint_laptop) {
            font-size: 15px;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
.builder-title {
  text-align: center;
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows{
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-group label{
  align-items: center;
  display: flex;
}