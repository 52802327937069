@import "../../../assets/scss/mixin";
.mini-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
  span { color: #999999; margin-right: 5px}
  cursor: pointer;
  @media (max-width: $breakpoint_laptop) {
    font-size: 15px;
    margin-bottom: 10px;
  }
}
.userid {
  margin: 0 0 19px;
  span { color: var(--theme-color); margin-left: 5px;}
}
.responsive-table {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 0 0 15px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &.activity_wrap {
    table {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  &.accordion-list {
    table {
      tr {
        th,td {
          padding: 10px;
          @media (max-width: $breakpoint_miniX_laptop) {
            font-size: 11px;
          }
          &:first-child {
            img {
              @media (max-width: $breakpoint_miniX_laptop) {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
  // @media (max-width: 1850px) {
  //   &.accordion-list {
  //     table {
  //       width: 1800px;
  //       max-width: 1800px;
  //     }
  //   }
  // }
  @media (max-width: $breakpoint_laptop) {
    &.accordion-list {
      // table {
      //   width: 1200px;
      //   max-width: 1200px;
      // }
      &.individuallist {
        table {
          width: 1350px;
          max-width: 1350px;
        }
      }
    }
  }
  // @media (max-width: $breakpoint_tablet) {
  //   table {
  //     max-width: 1000px;
  //     width: 1000px;
  //   }
  // }
  & > h3 {
    font-weight: 600;
    font-size: 28px;
    margin: 0;
    padding: 20px 0 17px 30px;
    border-bottom: 6px double #EBEBEB;
    @media (max-width: $breakpoint_laptop) {
      border-width: 4px;
      padding: 12px 0 10px 20px;
      font-size: 20px;
    }
  }
  .table-action {
    border: none;
    background: none;
    position: relative;
    padding: 0;
    margin: 0 8px;
    text-align: center;
    @media (max-width: $breakpoint_laptop) {
      img { height: 18px;}
    }
    &:hover {
      span {
        opacity: 1;
        visibility: visible;
        bottom: 150%;
      }
    }
    span {
      @include transition;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      z-index: 9;
      left: 50%;
      bottom: 100%;
      background: #FFFCED;
      transform: translateX(-50%);
      min-width: 110px;
      padding: 10px 15px;
      box-shadow: 0 6px 10px rgb(0 0 0 / 10%);
      border-radius: 4px;
      @media (max-width: $breakpoint_laptop) {
        padding: 7px 8px;
        min-width: 75px;
      }
      &:before {
        content: "";
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid #FFFCED;
        border-radius: 15px;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
      small {
        font-weight: 500;
        display: block;
        font-size: 16px;
        color: #fff;
        background: #999;
        border-radius: 4px;
        padding: 4px;
        white-space: nowrap;
        @media (max-width: $breakpoint_laptop) {
          font-size: 12px;
        }
      }
    }
    &.add {
      span {
        small { background: var(--theme-color)}
      }
    }
    &.view {
      span {
        small { background: #56C662}
      }
    }
    &.copy {
      span {
        small { background: #EBB322}
      }
    }
    &.update {
      span {
        small { background: #EA974B}
      }
    }
    &.del {
      span {
        small { background: #EA4B4B}
      }
    }
  }
  &.bank-table {
    tr {
      th,td {
        &:first-child { width: 20%;}
        &:last-child { width: 12%;}
      }
    }
  }
  &.last-child-table {
    tr {
      th,td {
        &:last-child { width: 12%;}
      }
    }
  }
}

.status {
  position: relative;
  display: flex;
  color: #000;
  white-space: nowrap;
  font-size: 16px;
  
  @media (max-width: $breakpoint_laptop) {
    font-size: 13px;
  }
  align-items: center;
  &.active {
    &:before { background: #22EB4E}
  }
  &.draft {
    color: #0004f3;
    &:before { background: #0004f3}
  }
  &.pending {
    color: #f8b018;
    &:before { background: #f8b018}
  }
  &.pending-documents {
    color: #f8b018;
    &:before { background: #f8b018}
  }
  &.submitted {
    color: #f8b018;
    &:before { background: #f8b018}
  }
  &.processing {
    color: #9a0ff3;
    &:before { background: #9a0ff3}
  }
  &.approved {
    color: #00b2ef;
    &:before { background: #00b2ef}
  }
  &.completed {
    color: #00c96a;
    &:before { background: #00c96a}
  }
  &.rejected {
    color: #ea0000;
    &:before { background: #ea0000}
  }
  &:before {
    content: '';
    margin-right: 6px;
    background: #999999;
    flex: 0 0 14px;
    height: 14px;
    border-radius: 100%;
    display: flex;
    @media (max-width: $breakpoint_laptop) {
      flex: 0 0 10px;
      height: 10px;
    }
  }
}
.status-wrap{
  span{
    &:before{
    content: none;
    } 
  }
}
.single-filed {
  margin-bottom: 30px;
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column;
  }
  h3 {
    margin: 0;
    @media (max-width: $breakpoint_mobile_portrait) {
      text-align: left;
      width: 100%;
    }
  }
  .form-control {
    width: 300px; margin-left: 20px;
    @media (max-width: $breakpoint_mobile_portrait) {
      margin-left: 0;
    }
  }
}