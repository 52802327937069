$background-color_1: #e6e6e6;
$background-color_2: #f9f9f9;
$background-color_3: #e0e0e0;
$background-color_4: #c6c6c6;

/* FileSystem.css */
.custom-file-system-container {
	display: flex;
	height: 90%;
}
.left-panel {
	width: 280px;
	flex: 0 0 280px;
	padding: 10px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
	border-radius: 10px;
	overflow-y: auto;
}
.right-panel {
	width: calc(100% - 270px);
	margin-left: 20px;
	padding: 10px 10px 0px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
	border-radius: 10px;
}
.right-panel-content{
	overflow-y: auto;
}
.h-20{
	height: 20px;
}
.uploaded-files{
	max-height: 255px;
	overflow-y: auto;
	padding-right: 5px;
}
.file_size{
	font-size: 12px;
	line-height: 1;
}
.file_dropzone {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 18px;
	color: var(--theme-color);
	background: #F7F7F7;
	border: 1px dashed #9C8831;
	border-radius: 5px;
	transition: all 0.3s;
	margin: 20px 0px;
	img { 
		margin-right: 10px;
		width: 30px;
	}
	label {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s;
	}
	&:hover, &:active, &:focus, &:hover:active, &:hover:focus {
		opacity: 0.7;
        box-shadow: 0px 0px 37px -2px rgba(0,0,0,0.75);
	}
	&.dragging {
        opacity: 0.7; 
        box-shadow: 0px 0px 37px -2px rgba(0,0,0,0.75);
		height: 200px;
    }
}
.selected{
	background-color: #EBDA90 !important;
	td{
		background-color: #EBDA90 !important;
	}
}
.directory-actions{
	button{
		background-color: transparent;
		border: none;
		img{
			width: 20px;
			height: 20px;
		}
	}
}
.custom-button-group{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	button{
		width: 48% !important;
		max-width: none;
		border-radius: 12px;
		height: 45px;
	}
}
.folder-breadcrumbs{
	display: flex;
	height: 50px;
	.anchor{
		margin-left: 4px;
		margin-right: 4px;
	}
}
.folder-table {
	margin-top: 20px;
	thead {
		tr {
			background-color: #9C8831;
			th {
				background-color: #9C8831;
				color: #fff;
				font-weight: normal;
			}
		}
	}
	tr {
		td {
			white-space: nowrap;
		}
	}
}
.custom-icon-buttons{
	background-color: transparent;
	border: 1px solid gray;
	border-radius: 8px;
	height: 50px;
	width: 50px;
	margin-left: 20px;
}
.button-group-1{
	display: flex;
	button{
		width: 220px;
		height: 45px;
		border-radius: 8px;
		font-size: 14px;
	}
}
.inner-folder-wrap {
	overflow: auto;	
.list-folder {
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
	background-color: transparent;
	color: #000;
	.selected-folder{
		background-color: #9C8831 !important;
		color: #fff !important;
	}
	.folder_name {
		text-overflow: ellipsis;
		width: calc(100% - 10px);
		overflow: hidden;
		img{
			width:20px
		}
	}
}
}