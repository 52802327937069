@import "../../assets/scss/mixin";
.header-wrapper {
  background: #ffffff;
  padding: 21px 50px 21px 60px;
  z-index: 9999;
  @media (max-width: $breakpoint_laptop) {
    padding: 10px 30px 10px 60px;
  }
  @media (max-width: $breakpoint_mini_laptop) {
    // padding: 6px 15px 6px 50px;
    padding: none;
  }
  small{
    font-weight: normal;
    font-size: 12px;
  }
  h1 {
    font-size: 36px;
    margin: 0;
    @media (max-width: $breakpoint_laptop) {
      font-size: 24px;
    }
    @media (max-width: $breakpoint_miniX_laptop) {
      font-size: 18px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      margin-left: auto;
      display: none;    
    }
    // @media (max-width: $breakpoint_mini_laptop) {
    //   margin-left: auto;
    //   display: none;
    // }
  }
 
  .right-header-section {
    figure {
      margin: 0 0 0 20px;
      position: relative;
      .user-notification{
        width: 30px;
        height: 32px;
        @media (max-width: $breakpoint_laptop) {
          width: 22px;
        }
      }
      &.select-drops {
        figcaption {
          padding: 4px 10px;
          a {
            border-bottom: 1px solid #EBEBEB;
            padding: 7px 0;
            &:last-child { border-bottom: none}
          }
        }
      }
      & > span {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        &.simple-btn {
          padding: 0 10px;
          max-width: unset;
          color: #fff;
          font-weight: 400;
          font-size: 15px;
          margin-right: 0;
          &:after {
            border-top-color: #fff;
          }
        }
        img {
          width: 58px;
          height: 58px;
          border-radius: 100%;
          object-fit: cover;
          display: flex;
          @media (max-width: $breakpoint_laptop) {
            width: 46px;
            height: 46px;
          }
          @media (max-width: $breakpoint_mini_laptop) {
            width: 36px;
            height: 36px;
          }
        }
        &:after {
          content: '';
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 8px solid #8F8F8F;
          border-radius: 5px;
          margin-left: 8px;
        }
      }
      figcaption {
        position: absolute;
        right: 0;
        top: 100%;
        width: 200px;
        background: #fff;
        box-shadow: 0 0 10px rgb(0 0 0 / 20%);
        margin: 13px 0 0 0;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        &.notification-wrapper {
          max-height: 410px;
          overflow-y: scroll;
          width: 400px;
          padding: 0;
          transform: translateX(78px);
          @media (max-width: $breakpoint_mobile_portrait) {
            width: 300px;
          }
          &:before {
            content: "";
            position: absolute;
            right: 7px;
            top: -14px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 14px solid #fff;
          }
          h5 {
            margin: 0;
            padding: 16px 16px 10px;
            border-bottom: 1px solid #EBEBEB;
            @media (max-width: $breakpoint_laptop) {
              font-size: 16px;
            }
          }
          section {
            border-bottom: 1px solid #EBEBEB;
            &:last-child { border-bottom: none;}
            padding: 16px;
            h6 {
              margin: 0 0 5px;
              font-weight: 600;
            }
            p {
              margin: 0 0 5px;
            }
            time {
              display: block;
              text-align: right;
              color: darkgray
            }
            img{
              height: 30px;
              width: 30px;
              margin: 0px;
              padding: 0px;
              margin-left: auto;
              cursor: pointer;
            }
          }
        }
        a { padding: 2px 5px}
      }
    }
  }
}

.mt-1{
  margin-top: 10px;
}