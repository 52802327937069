@import "../../../assets/scss/mixin";
.detail-table{
  table {
    tr {
      box-shadow: none;
      th {
        font-weight: 600;
        font-size: 24px;
        padding: 6px 0;
        width: 300px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 18px;
        }
      }
      td {
        font-size: 20px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 15px;
        }
      }
    }
  }
}