@import "../../../../assets/scss/mixin";
.member-wrapper {
  &> ul {
    margin: 0 -20px;
    @media (max-width: $breakpoint_laptop) {
      margin: 0 -10px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      flex-direction: column;
    }
    & > li {
      width: 50%;
      padding: 0 20px;
      margin-bottom: 40px;
      @media (max-width: $breakpoint_laptop) {
        margin-bottom: 30px;
        padding: 0 10px;
      }
      @media (max-width: $breakpoint_tablet) {
        width: 50%;
      }
      @media (max-width: $breakpoint_mobile_landscape) {
        width: 50%;
      }
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 100%;
      }
      .member-text {
        background-color: #FFFFFF;
        box-shadow: 0 30px 72px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        h2 {
          margin: 0;
          font-weight: 400;
          padding: 22px 28px;
          border-bottom: 1px solid #EBEBEB;
          @media (max-width: $breakpoint_laptop) {
            padding: 12px 20px;
            font-size: 16px;
          }
          @media (max-width: $breakpoint_miniX_laptop) {
            padding: 9px 12px;
            font-size: 14px;
          }
        }
        section {
          padding: 20px 20px 9px;
          @media (max-width: $breakpoint_laptop) {
            padding: 5px 15px 9px;
          }
          @media (max-width: $breakpoint_miniX_laptop) {
            padding: 5px 10px 9px;
          }
          ul {
            li {
              font-size: 24px;
              margin-bottom: 11px;
              line-height: 38px;
              @media (max-width: $breakpoint_laptop) {
                font-size: 15px;
                margin-bottom: 0;
              }
              @media (max-width: $breakpoint_miniX_laptop) {
                font-size: 13px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
  & > a {
    font-size: 20px;
    font-weight: 500;
    margin: 0 auto;
    color: var(--theme-color);
    @media (max-width: $breakpoint_laptop) {
      font-size: 16px;
    }
    &:hover {
      i {
        margin-left: 15px;
      }
    }
    i {
      margin-left: 6px;
      font-size: 25px;
      margin-bottom: -2px;
      @include transition;
      @media (max-width: $breakpoint_laptop) {
        font-size: 20px;
      }
    }
  }
}