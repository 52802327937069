@import "../../../../assets/scss/mixin";
.accordion-list-wrapper {
  padding: 0 0 0;
}
.accordion-list {
  &.cases-list {
    tr {
      th,td{
        &:first-child {
          width: auto;
        }
      }
    }
  }
  tr {
    th,td {
      padding: 10px;
      &:first-child{
        width: 50px;
        white-space: nowrap;
        padding: 0 0 0 20px;
      }
    }
    span.number {
      display: inline-block;
      small { text-align: center; display: block}
    }
  }
}

.donut-chart{
  width: 100px;
  flex: 0 0 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  @media (max-width: $breakpoint_miniX_laptop) {
    width: 50px;
    flex: 0 0 50px;
  }
  span{
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    @media (max-width: $breakpoint_miniX_laptop) {
      font-size: 15px;
    }
  }
}