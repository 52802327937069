@import "../../../assets/scss/mixin";
.team-wrapper {
  margin: 0 -8px;
  @media (max-width: $breakpoint_tablet) {
    flex-direction: column;
  }
  .team-list {
    width: 33.333%;
    padding: 0 8px;
    @media (max-width: $breakpoint_tablet) {
      width: 100%;
    }
    .table {
      @media (max-width: $breakpoint_tablet) {
        width: 100%;
        max-width: 100%;
      }
      tr {
        th,td {
          &:last-child {
            width: 150px;
            @media (max-width: $breakpoint_laptop) {
              width: 100px;
            }
          }
        }
      }
    }
    .table-action {
      &.assigned {
        img { transform: scale(-1)}
      }
    }
  }
}
.selected-team{
  background-color: #c4c4c4 !important;
}