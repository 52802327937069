@import "../../../../assets/scss/mixin";
.top-strip {
  h3 {
    width: 50%;
    margin: 0 0 24px;
    font-weight: 400;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    color: #000;
    span {
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: 100%;
      font-weight: bold;
      height: 66px;
    }
    @media (max-width: $breakpoint_laptop) {
      font-size: 18px;
      span {
        height: 40px;
      }
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      width: 100%;
      margin-top: 10px;
    }
  }
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column-reverse;
  }
  .status-wrap {
    small:last-of-type {
     font-weight: 600;
    }
    .status {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      justify-content: flex-end;
      @media (max-width: $breakpoint_laptop) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    small {
      display: block;
      font-size: 16px;
      font-weight: 500;
      @media (max-width: $breakpoint_laptop) {
        font-size: 12px;
      }
    }
  }
}
.no-br{
  border-radius: 0px !important;
}
.entity-type-tab {
  margin-bottom: 23px;
  ul {
    li {
      button {
        background: #F1EFEA;
        border-radius: 12px 0 0 12px;
        font-size: 16px;
        padding: 0 49px;
        border: none;
        color: #999999;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $breakpoint_laptop) {
          font-size: 15px;
          padding: 0 30px;
          height: 45px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          font-size: 13px;
          padding: 0 12px;
        }
        &.brr-0{
          border-radius:12px 0px 0px 12px ;
        }
        &.brl-0{
          border-radius:0px 12px 12px 0px ;
        }
        &.br-0{
          border-radius:0px 0px 0px 0px ;
        }
        &.active {
          font-weight: 600;
          font-size: 24px;
          color: var(--theme-color);
          background: #E4E0CF;
          box-shadow: 8px 0 10px -5px rgba(0, 0, 0, 0.24);
          @media (max-width: $breakpoint_laptop) {
            font-size: 17px;
          }
          @media (max-width: $breakpoint_mobile_portrait) {
            font-size: 13px;
          }
        }
      }
      &:last-child {
        button{
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }
}
.case-information {
  .accordion__item {
    @media (max-width: $breakpoint_miniX_laptop) {
      box-shadow: 0 2px 9px rgb(0 0 0 / 6%);
    }
  }
  .case-dorpdown {
    margin-bottom: 40px;
    @media (max-width: $breakpoint_laptop) {
      margin-bottom: 25px;
    }
    img {
      width: 43px;
      @media (max-width: $breakpoint_laptop) {
        width: 30px;
      }
    }
    select {
      height: 52px;
      width: 100%;
      max-width: 219px;
      font-size: 16px;
      padding-left: 20px;
      border-radius: 4px;
      background: var(--theme-color) url("../../../../assets/images/select-down-arrow-white.svg")no-repeat 95%;
      color: #FFFFFF;
      border: none;
      margin-right: 20px;
      @media (max-width: $breakpoint_laptop) {
        font-size: 15px;
        height: 40px;
      }
    }
  }
  & > .flex {
    .simple-btn {
      max-width: unset;
      width: auto;
      padding: 0 20px;
      font-weight: 500;
      color: #FFFFFF;
      &.manager {
        background: #0AC280;
        border-color: #0AC280;
        padding: 0 40px;
      }
    }
  }
  .accordion__button {
    background: rgba(156,129,49,0.06) !important;
    border-radius: 12px 12px 0 0 !important;
    font-weight: 600;
    color: var(--theme-color) !important;
  }
  .accordion__panel {
    padding: 19px 0 0 !important;
    .responsive-table {
      overflow: visible;
      border-radius: 0;
      padding-bottom: 0;
      &.lead-active-log-table {
        overflow: auto;
      }
      &.case-information-table {
        @media (max-width: $breakpoint_mobile_portrait) {
          //overflow: auto;
          table {
            width: 100%;
          }
        }
      }
      tr {
        td {
          .docs-list {
            max-width: 70%;
          }
        }
      }
      .first-table {
        tr {
          th,td {
            font-size: 20px;
            &:first-child { width: 30%;}
            @media (max-width: $breakpoint_laptop) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.document-wrapper {
  padding: 0 30px 50px 20px;
  @media (max-width: $breakpoint_mobile_portrait) {
    flex-direction: column-reverse;
  }
  .latest-comment {
    width:50%;
    padding: 15px 20px 20px;
    background: #F7F7F7;
    border-radius: 4px;
    max-height: 655px;
    overflow: auto;
    @media (max-width: $breakpoint_laptop) {
      max-height: 500px;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      margin-bottom: 30px;
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #DBDBDB;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--theme-color);
    }
    h3 {
      font-size: 20px;
      color: #9C8831;
      margin: 0 0 20px;
      font-weight: 700;
      @media (max-width: $breakpoint_laptop) {
        font-size: 18px;
      }
    }
    ul {
      li {
        margin: 0 0 20px;
        padding-left: 35px;
        position: relative;
        @media (max-width: $breakpoint_laptop) {
          padding-left: 25px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 7px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background: #9C8831;
          @media (max-width: $breakpoint_laptop) {
            width: 10px;
            height: 10px;
          }
        }
        ul {
          li {
            &:before {
              content: none;
            }
            & > span { display: flex;}
          }
        }
        h6 {
          font-weight: 700;
          font-size: 20px;
          color: #000000;
          margin: 0 0 5px;
          @media (max-width: $breakpoint_laptop) {
            font-size: 16px;
          }
        }
        small {
          display: block;
          color: #999999;
          font-size: 20px;
          @media (max-width: $breakpoint_laptop) {
            font-size: 16px;
          }
        }
        span {
          font-size: 20px;
          color: #000000;
          display: block;
          margin-bottom: 8px;
          @media (max-width: $breakpoint_laptop) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.cases-doc-list {
  width: 50%;
  padding: 0 25px 0 0;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100%;
  }
  button {
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 15px;
  }
 
  ul {
    max-height: 655px;
    overflow: auto;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    @media (max-width: $breakpoint_laptop) {
      max-height: 500px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #DBDBDB;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--theme-color);
    }
    li {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &.title {
        padding-left: 0;
        margin-bottom: 10px;
        h2 {
          text-align: left;
          margin: 0;
        }
      }
      h2 {
        margin: 20px 0 0;
        @media (max-width: $breakpoint_laptop) {
          font-size: 18px;
        }
      }
      h4 {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        font-size: 24px;
        font-weight: 500;
        @media (max-width: $breakpoint_laptop) {
          font-size: 14px;
        }
        h2 { margin-bottom: 0 !important;}
        img {
          margin-left: 10px;
          @media (max-width: $breakpoint_laptop) {
            margin-left: 5px;
          }
        }
        &:before {
          content: '';
          margin-right: 7px;
          background-image: url("../../../../assets/images/check-doc.svg");
          // background-image: url("../../../../assets/images/reject-doc.svg");
          background-size: 100% 100%;
          width: 24px;
          height: 24px;
          @media (max-width: $breakpoint_laptop) {
            width: 18px;
            height: 18px;
          }
        }
        &.reject {
          color: #FF2116;
          &:before {
            background-image: url("../../../../assets/images/reject-doc.svg");
          }
        }
        &.waiting {
          &:before {
            background-image: url("../../../../assets/images/waiting.svg");
          }
        }
        &.pending {
          &:before {
            color: #F2C94C;
          }
        }
      }
      span {
        border-radius: 12px;
        position: relative;
        small { color: #999999; margin-left: 3px;}
        img {
          margin-right: 30px;
          flex: 0 0 43px;
          @media (max-width: $breakpoint_laptop) {
            margin-right: 15px;
            flex: 0 0 20px
          }
        }
        border: 1px solid var(--theme-color);
        display: flex;
        align-items: center;
        font-size: 20px;
        align-self: flex-start;
        padding: 20px 160px 20px 30px;
        font-weight: 600;
        width: 100%;
        @media (max-width: $breakpoint_laptop) {
          font-size: 15px;
          padding: 20px 95px 20px 20px;
        }
      }
      a{
        font-size: 24px;
        color: #0A40FC;
        @media (max-width: $breakpoint_laptop) {
          font-size: 14px;
        }
      }
    }
  }
}
.business-information-list {
  padding: 0 30px;
  @media (max-width: $breakpoint_mobile_portrait) {
    padding: 0 10px;
  }
  ul {
    li {
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      h4 {
        margin: 0;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          font-size: 14px;
        }
      }
      span {
        color: #000000;
        font-size: 24px;
        margin-left: auto;
        text-align: right;
        width: 40%;
        @media (max-width: $breakpoint_laptop) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint_mobile_portrait) {
          font-size: 14px;
        }
      }
    }
  }
}
.history {
  table {
    tr {
      th,td {
        font-size: 20px;
        padding: 15px;
        @media (max-width: $breakpoint_laptop) {
          font-size: 14px;
        }
      }
    }
  }
}

.extra-field {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
  }
  img {
    margin-left: 10px;
    @media (max-width: $breakpoint_laptop) {
      margin-left: 5px;
    }
  }
}
.log-listing {
  display: flex;
  .accept{
    background-color: #a28731 !important;
    border-color: #a28731 !important;
    width: 180px !important;
    border-radius: 5px !important;
  }
  .cancel{
    width: 180px !important;
    border-radius: 5px !important;
    color: #999999 !important;
    font-weight: 500 !important;
  }
  .cancel:hover{
    color: #FFFFFF !important;
  }
  h2 {
    display: flex;
    align-items: center;
    background: rgba(156, 129, 49, 0.06);
    border-radius: 12px 12px 0 0;
    font-weight: 600;
    color: var(--theme-color);
    font-size: 24px;
    padding: 25px 18px;
    @media (max-width: $breakpoint_laptop) {
      padding: 12px 18px;
      font-size: 16px;
    }
  }
  ul {
    margin: 0 -5px;
    li {
      padding: 0 5px;
      width: 24%;
      margin-bottom: 10px;
      align-items: center;
      display: flex;
      &:last-child { width: auto}
      .form-control {
        font-size: 18px;
      }
    }
  }
}
.del-btn,.add-list {
  background: none;
  border: none;
}
.add-list { margin: 0 0 10px auto;}
.blank-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #B83C3C;
  color: #FFFFFF;
  border: none;
  padding: 11px 16px;
  border-radius: 10px;
  img {
    width: 18px;
    margin-right: 11px;
  }
  &:hover { box-shadow: 0 0 10px rgba(0,0,0,0.2)}
  &.accepted {
    img {
      filter: brightness(1);
    }
    background: #56C662 !important;
  }
}
.add-amount-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border: 2px solid #F7F7F7;
  border-radius: 0 0 5px 5px;
  margin-top: -20px;
  .add-amount-wrap {
    & > div {
      margin-bottom: 10px;
      & > input { text-align: center; margin-right: 25px;}
      label {
        font-size: 14px;
        display: flex;
        align-items: center;
        input {
          width: 25px;
          height: 25px;
          border-radius: 10px;
          margin-right: 10px;
          margin-left: 20px;
        }
      }
    }
    .simple-btn { margin-left: auto}
  }
}
.bank-status-wrap {
  gap: 20px;
  margin-top: 20px !important;
  justify-content: flex-end;
  li { width: unset !important;}
}
.log-listing > img {
  float: right;
}
.case-status {
  color: var(--white-color);
  background-color: #EA4B4B;
  border-radius: 50px;
  padding: 4px 9px;
  &.completed {
    border-radius: 4px;
    font-size: 20px;
    padding: 9px 20px;
    background-color: #56C662;
  }
}
.banklist-wrapper {
  background: #F7F7F7;
  padding: 20px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  .case-status {
    border-radius: 50px;
    padding: 7px 36px;
    font-size: 14px;
    &.false { background: #EDB5B5; color: #674141}
    &.completed { background: #B5EDBA; color: #336938;}
  }
  h3 {
    font-weight: bold;
    margin: 0 0 10px;
    font-size: 18px;
  }
  .selection-modal {
    position: relative;
    &.active {
      img { pointer-events: none}
    }
    img {
      cursor: pointer;
    }
    .selection_drop {
      position: absolute;
      right: 0;
      top: 100%;
      padding: 16px 4px;
      z-index: 1;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05), 5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 200px;
      button {
        background: transparent;
        border: none;
        width: 100%;
        text-align: left;
        padding: 4px;
        margin-bottom: 12px;
        &:last-child { margin-bottom: 0}
        @include transition;
        &:hover {
          color: #FFFFFF;
          background: var(--theme-color);
        }
      }
    }
  }
  .form-control {
    height: 40px;
    max-width: 317px;
    background: #FFFFFF !important;
  }
}
.add-list-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 18px;
  color: var(--theme-color);
  background: #F7F7F7;
  border: 1px dashed #9C8831;
  border-radius: 5px;
  img { margin-right: 10px;}
  margin: 0 0 20px;
}
.action_button_wrap {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  img {
    margin: 0 !important;
  }
  & > img {
    margin-right: 36px !important;
    flex: unset !important;
    @media (max-width: $breakpoint_laptop) {
      margin-right: 15px !important;
    }
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0 36px 0 0 !important;
    @media (max-width: $breakpoint_laptop) {
      margin-right: 15px !important;
    }
  }
}
.switches-area {
  ul {
    li {
      span { margin: 0; padding: 0}
      padding: 0 10px;
      flex-direction: column;
      display: flex;
      width: 25%;
    }
  }
}
.modal-forms {
  h2{
    text-align: left !important;
  }
  .add-list-wrapper { margin: 10px 0 0}
}

.bordered-form{
  border: 1px solid #9C8831;
  display: flex;
  justify-content: center !important;
  border-radius: 10px;
  padding-left: 20px;
  padding: 15px;
  cursor: pointer;
  h4{
    margin: 0px !important;
  }
}
.w-33{
  width: 33.3% !important;
  white-space: nowrap;
}