@import "../../../assets/scss/mixin";
.main-form-wrapper {
  background: url("../../../assets/images/login-bg1.png") bottom center no-repeat;
  background-size: contain;
  min-height: 100vh;
  .form-wrapper {
    max-width: 64%;
    margin: 0 auto;
    @media (max-width: $breakpoint_laptop) {
      max-width: 48%;
    }
    @media (max-width: $breakpoint_mobile_portrait) {
      max-width: 100%;
    }
    & > img {
      max-width: 300px;
      margin: 0 auto 35px;
      //  margin: 0 0 0px;
      @media (max-width: $breakpoint_laptop) {
        margin: 0 auto 35px;
        max-width: 200px;
      }
      @media (max-width: $breakpoint_tablet) {
        margin-bottom: 10px;
      }
    }
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      margin: 0 0 56px;
      @media (max-width: $breakpoint_laptop) {
        margin-bottom: 25px;
        font-size: 30px;
      }
      @media (max-width: $breakpoint_mobile_landscape) {
        font-size: 22px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        margin-bottom: 36px;
        align-items: center;
        flex-direction: column;
        &:last-child { margin-top: 30px}
        @media (max-width: $breakpoint_laptop) {
          margin-bottom: 25px;
          &:last-child { margin-top: 15px}
        }
        .forgot {
          margin-left: auto;
          font-weight: 500;
          font-size: 22px;
          line-height: 27px;
          text-decoration: underline;
          color: var(--theme-color);
          margin-top: 16px;
          @media (max-width: $breakpoint_laptop) {
            font-size: 16px;
            margin-top: 10px;
          }
        }
        label {
          color: var(--black-color);
          font-weight: 500;
          font-size: 20px;
          margin: 0 0 15px;
          line-height: 1;
          width: 100%;
          @media (max-width: $breakpoint_laptop) {
            font-size: 16px;
            margin: 0 0 10px;
          }
        }
        .form-group {
          position: relative;
          width: 100%;
          display: flex;
          margin: 0;
          flex-direction: column;
          &.password {
            & > img {
              cursor: pointer;
            }
          }
          & > img {
            position: absolute;
            right: 30px;
            top: 27px;
            @media (max-width: $breakpoint_laptop) {
              right: 20px;
              top: 17px;
              max-height: 15px;
            }
          }
        }
      }
    }
  }
}
.field-error {
  display: block;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.7);
  color: #ffffff;
  margin: 5px 0 0;
  padding: 5px 0;
}
.simple-btn {
  color: #EBEBEB;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  width: 100%;
  max-width: 230px;
  display: flex;
  border: 2px solid #000000;
  height: 60px;
  background: #000000;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  white-space: nowrap;
  margin-right: 15px;
  &.colored {
    background-color: #0AC280;
    border-color: #0AC280;
  }
  &.red{
    background-color: #ff0000;
    border-color: #ff0000;
  }
  &.golden {
    background-color: #9C8831;
    border-color: #9C8831;
  }
  &:last-child { margin-right: 0;}
  &.transparent { background: transparent; color: var(--theme-color)}
  &.submit {
    background: #000000;
    border-color: #000000;
    &.transparent {
      background: transparent; color: var(--theme-second-color)
    }
  }
  &.create-form {
    max-width: 320px;
    @media (max-width: $breakpoint_laptop) {
      max-width: 230px;
    }
  }
  &.cancel {
    border-color: #999999;
    background: transparent;
    color: #999999;
  }
  &:hover {
    background: var(--theme-color);
    color: var(--white-color);
    border-color: var(--theme-color);
  }
  @media (max-width: $breakpoint_laptop) {
    font-size: 15px;
    height: 48px;
    max-width: 170px;
  }
}
.button-wrapper {
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  @media (max-width: $breakpoint_tablet) {
    margin-top: 0;
  }
  .simple-btn {
    margin: 0 15px;
    @media (max-width: $breakpoint_laptop) {
      margin: 0 10px;
    }
  }
}
label { text-transform: capitalize}
.passwordHide {
  &:before {
    content: '/';
    position: absolute;
    right: 36px;
    top: 22px;
    font-size: 30px;
    color: #999999;
    @media (max-width: $breakpoint_laptop) {
      right: 28px;
      top: 13px;
      font-size: 20px;
    }
  }
}