.rml-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 600px) {
        display: none !important;
    }
}
.rml-container .react-date-picker{
    width: 200px;
}
.mb-0{
    margin-bottom: 0px !important;
}


.no-text-decoration{
    text-transform: none !important;
}

.graph_grid_wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -10px;
    .graph_grid_card{
        padding: 0 10px;
        width: 33.333%;
        margin: 0 0 20px;
        position: relative;
        font-size: 18px;
        font-weight: 600;
        @media (max-width: 600px) {
            width: 100%;
        }
        .statistics-tabs {
            top: 0;
            bottom: unset;
            z-index: 1;
            left: unset;
            right: 20px;
            span {
                margin: 0 0 0 5px;
                padding: 11px 10px 0;
                @media (max-width: 1280px) {
                    font-size: 14px;
                    padding: 11px 5px 0;
                }
            }
        }
        .graph_grid_box {
            background: white;
            padding: 10px;
            border-radius: 10px;
            position: relative;
            font-size: 18px;
            font-weight: 600;
            .box-title {
                border-bottom: 1px solid lightgray;
                @media (max-width: 1280px) {
                    font-size: 13px;
                }
            }
            h2 {
                position: absolute;
                top: 20px;
                display: flex;
                justify-content: center;
                font-size: 28px;
                letter-spacing: 1px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
.custom-width{
    width: 33.3%;
    @media (max-width: 600px) {
        width: 100% !important;
    }

}
.custom-height{
    height: 90%;
    ul{
        height: 100%;
        li{
            height: 100%;
            a{
                height: 100%;
            }
        }
    }
}
.f-16{
    font-size: 16px !important;
    font-weight: normal !important;
}

.month{
    font-size: 16px !important;
    color: #a28732;
    display: flex;
    justify-content: center;
}

.info{
    font-size: 16px !important;
    font-weight: normal;
    // color: #a28732;
    display: flex;
    justify-content: center;
}

.revenue{
    font-size: 16px !important;
    color: #a28732;
    // display: flex;
}

.custom-tooltip{
    font-size: 16px;
    background-color: rgb(246, 246, 246,0.7);
    width:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 30px;
    z-index: 10 !important;
}

.recharts-rectangle.recharts-tooltip-cursor{
    fill-opacity: 0 !important;
    z-index: 100000;
    // fill: transparent;
}
.recharts-bar-rectangles:hover {
    fill: transparent !important; /* Set the fill to transparent */
  }
.h-100{
    height: 100%;
}
.report-table-wrapper{
    width: 100%;
    >div{
        margin-bottom: 50px;
    }
}