@import './_variables.scss';
@mixin d_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@mixin flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@mixin d_flex_column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@mixin center_align {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin max_width {
  width: auto;
  max-width: $w_100;
}
@mixin position_abs_left {
  position: $position_abs;
  left: 0;
  top: 0;
}
@mixin transition {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}
@mixin position_abs_right {
  position: $position_abs;
  right: 0;
  top: 0;
}
@mixin _before_position_abs{
  content:"";
  @include position_abs_left;
}
@mixin _after_position_abs{
  content:"";
  @include position_abs_left;
}
@mixin flex_ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
@mixin user_img {
  border-radius: 100%;
  object-fit: cover;
}
@mixin list_style_none {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin font_face_design {
  font-style: normal;
  font-display: swap;
}
